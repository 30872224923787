import { grey } from "@mui/material/colors";
import { COLORS } from "../../constants";

export default {
  customBackground: {
    gray: grey[800],
    gradient: "linear-gradient(90deg, #226798, #7e6498)",
    image:
      "url(https://cdn.pixabay.com/photo/2021/09/25/10/08/road-6654573_960_720.jpg) 0% 0% / cover",
  },
  gradients: {
    solidBlack50: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
    solidWhite50:
      "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
    solidSecondary60:
      "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
  },
  social: {
    facebookColor: grey[700],
    twitterColor: grey[700],
    linkedinColor: grey[700],
    instagramColor: grey[700],
    youtubeColor: grey[700],
  },
  appBar: {
    background: grey[800],
    color: grey[50],
  },
  footer: {
    background: grey[800],
  },
  // ////////////////////////////////////////
  // DEFINE MAIN AND SECONDARY FOR DARK THEME
  primary: {
    main: COLORS.MAIN.PRIMARY,
  },
  secondary: {
    main: COLORS.MAIN.SECONDARY,
  },
  dashboard: {
    sidebar: {
      background: COLORS.MAIN.PRIMARY,
      color: "white",
    },
    content: {
      background: "#3DBAD2",
    },
  },
};
