import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import AddressHistoryActions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";

/**
 * @name startFetchAddressHistoryFlow
 * A redux saga that fetches an address's history from the NeedsMD Provider Endpoint.
 */
export const startFetchAddressHistoryFlow = makeEntityActionSaga({
  onStart: () => put(AddressHistoryActions.setNetworkIdle()),
  onCall: ({ providerId, addressId, params }) =>
    call<any>(
      API.Address.History.npiAddressHistory,
      { providerId, addressId },
      params
    ),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(AddressHistoryActions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(AddressHistoryActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      AddressHistoryActions.setError({
        type: "FETCH_ADDRESS_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/addressHistory",
  sagas: {
    fetchAddressHistory: startFetchAddressHistoryFlow,
  },
});
