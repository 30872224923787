import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthorizationRoles } from "../../../../constants/authorization/roles";
import ROUTES from "../../../../constants/routes";
import { RestrictedAccess } from "../../../commons/status/RestrictedAccess";
import { useAuthentication } from "../../../auth/providers/AuthProvider/hooks";
import { useAuthorization } from "../../../authorization/providers/AuthorizationProvider/hooks";

export interface IPrivateRouteProps {
  component: JSX.Element;
  roles: Array<AuthorizationRoles>;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component: RouteComponent,
  roles,
}) => {
  const { isAuth } = useAuthentication() || {};
  const { role } = useAuthorization();
  const location = useLocation();

  // Verify roles
  const userHasRequiredRole = role && roles.includes(role);

  if (isAuth) {
    if (!userHasRequiredRole) {
      return <RestrictedAccess />;
    }
    return RouteComponent;
  }

  return <Navigate to={ROUTES.LOGIN} state={{ from: location }} />;
};
