import { createTheme } from "@mui/material";
import { merge } from "lodash";
import BaseTheme from "../base";
import palette from "./palette";

export const PartialDarkTheme = merge(BaseTheme, {
  palette,
});

export default createTheme(PartialDarkTheme);
