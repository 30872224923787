import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";

/**
 * @name startFetchCommentHistoryFlow
 * A redux saga that fetches a Comment's history from the NeedsMD Provider Endpoint.
 */
export const startFetchCommentHistoryFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ npiId, commentId, params }) =>
    call<any>(API.Comment.History.npiCommentHistory, npiId, commentId, params),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(Actions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_COMMENT_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/commentHistory",
  sagas: {
    fetchCommentHistory: startFetchCommentHistoryFlow,
  },
});
