/**
 * A function set to check if an instance of localStorage exists in the current context.
 * @param key A string literal to refer to the stored value.
 * @returns The value stored in the storage.
 */
export const checkIfStorageExists = () => {
  if (!localStorage) {
    throw new Error("[Service -> LocalStorage]: No localStorage is available!");
  }
};

/**
 * A function to retrieve a stored value from the localStorage.
 * @param key A string literal to refer to the stored value.
 * @returns The value stored in the storage.
 */
export const readFromStorage = (key: string) => {
  checkIfStorageExists();

  return localStorage.getItem(key);
};

/**
 * A function to write/store a value into the localStorage.
 * @param key A string literal to refer to the stored value.
 * @param value A data entry to be stored in the storage.
 */
export const writeToStorage = (key: string, value: any) => {
  checkIfStorageExists();

  localStorage.setItem(key, value);
};

/**
 * A function set to remove a value from the localStorage.
 * @param key A string literal to refer to the stored value.
 */
export const deleteFromStorage = (key: string) => {
  checkIfStorageExists();

  return localStorage.removeItem(key);
};
