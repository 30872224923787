/* eslint-disable @typescript-eslint/naming-convention */
import { entityToMap, getArrayFromMap } from "../../utils/normalization";

export const normalizeProviderTaxonomySet = (providerTaxonomyList: any[]) => {
  const providerTaxonomyMap = new Map();
  const taxonomyMap = new Map();

  providerTaxonomyList.forEach((providerTaxonomy) => {
    const newProviderTaxonomy = { ...providerTaxonomy };
    const { specialty_class } = providerTaxonomy.taxonomy;

    // Add addresses to AddressMap
    newProviderTaxonomy.taxonomy = entityToMap(
      {
        ...providerTaxonomy.taxonomy,
        specialty_class_name: specialty_class ? specialty_class.name : "",
        specialty_class_id: specialty_class ? specialty_class.id : "",
      },
      taxonomyMap
    );

    entityToMap(newProviderTaxonomy, providerTaxonomyMap);
  });

  return {
    taxonomies: getArrayFromMap(taxonomyMap),
    providerTaxonomies: getArrayFromMap(providerTaxonomyMap),
  };
};
