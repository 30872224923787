import * as React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles, createStyles } from "@mui/styles";
import AdjustIcon from "@mui/icons-material/Adjust";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MedicalServicesTwoToneIcon from "@mui/icons-material/MedicalServicesTwoTone";

import MessageComponent from "../../../messageCenter/layout/MessageComponent";
import BarComponent from "../../fragments/BarComponent";
import { SideMenuBar, MenuItem } from "../../fragments/SideMenuBar";
import ROUTES from "../../../../constants/routes";
import { AuthorizationComponents } from "../../../../constants/authorization/views";
import { useAuthentication } from "../../../auth/providers/AuthProvider/hooks";

const useStyles = makeStyles(() =>
  createStyles({
    layout: {
      display: "flex",
      flexFlow: "column",
      minHeight: "100vh",
    },
    contentWrapper: { display: "flex", flexDirection: "row", flex: 1 },
    content: { flex: 1 },
    dashboardContent: { display: "flex", flex: 1, flexDirection: "column" },
  })
);

export interface INavigationLayoutProps {
  auth: boolean;
}

export const NavigationLayout = (
  props: React.PropsWithChildren<INavigationLayoutProps>
) => {
  const { isAdmin } = useAuthentication();
  const { children, auth, ...rest } = props;
  const { dashboardContent, layout, contentWrapper, content } = useStyles();
  const [open, setOpen] = React.useState(true);

  // TODO: Define a proper abstracted menu
  const items: MenuItem[] = [
    {
      label: "Dashboard",
      path: ROUTES.DASHBOARD,
      Icon: AdjustIcon,
      authorizationComponent: AuthorizationComponents.DASHBOARD,
    },
    {
      label: "Manage Users",
      path: ROUTES.USERLIST,
      Icon: AccountCircleIcon,
      authorizationComponent: AuthorizationComponents.MANAGE_USER,
    },
    {
      label: "Manage Accounts",
      path: ROUTES.ACCOUNTLIST,
      Icon: CorporateFareIcon,
      authorizationComponent: AuthorizationComponents.MANAGE_ACCOUNT,
    },
    {
      label: isAdmin ? "Master Providers" : "Providers",
      path: ROUTES.MASTERPROVIDERLIST,
      Icon: MedicalServicesTwoToneIcon,
      authorizationComponent: AuthorizationComponents.MASTER_PROVIDER,
    },
    // {
    //   label: "Providers",
    //   path: ROUTES.PROVIDERLIST,
    //   Icon: MedicalServicesIcon,
    //   authorizationComponent: AuthorizationComponents.PROVIDER,
    // },
  ];

  const LayoutContent = auth ? (
    <Box className={contentWrapper}>
      <Box component="aside">
        <SideMenuBar
          items={items}
          open={open}
          onToggle={() => setOpen(!open)}
          sx={{ display: { xs: "none", sm: "block" } }}
        />
      </Box>
      <Box component="main" className={dashboardContent}>
        <MessageComponent />
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Box component="main" className={content}>
      <Outlet />
    </Box>
  );

  return (
    <Box className={layout}>
      <BarComponent
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        position="fixed"
        auth={auth}
        {...rest}
      />
      <Toolbar />
      {LayoutContent}
    </Box>
  );
};

export default NavigationLayout;
