import { EntitySelectors, createSelector } from "@reduxjs/toolkit";

import Factory from "./factory";
import { INormalizedProvider } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.providers;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  INormalizedProvider, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);
export const Pagination = Factory.selectors.getPaginationSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};

export const selectProviderPagination = createSelector(
  (state: RootState, props: number) => props,
  (state: RootState) => Entity.selectAll(state),
  (state: RootState) => Pagination.getPaginationState(state),
  (id, providers, { pageSize, currentPage, total }) => {
    let previousProvider = 0;
    let nextProvider = 0;
    let isFetchPreviousPage = false;
    let isFetchNextPage = false;

    const index = providers.findIndex(
      (provider) => `${provider.id}` === `${id}`
    );

    if (providers.length > 1) {
      if (index > 0) {
        previousProvider = providers[index - 1].id;
      } else if (currentPage > 1) {
        isFetchPreviousPage = true;
      }
    }

    if (providers.length > 1) {
      if (index < pageSize - 1) {
        nextProvider = providers[index + 1].id;
      } else if (currentPage < total / pageSize) {
        isFetchNextPage = true;
      }
    }

    return {
      index,
      currentPage,
      previousProvider,
      nextProvider,
      isFetchPreviousPage,
      isFetchNextPage,
    };
  }
);

export default {
  Entity,
  Error,
  Network,
  Pagination,
  // Custom,
  selectProviderPagination,
};
