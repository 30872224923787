import * as React from "react";
import { AuthorizationActions } from "../../../../constants/authorization/actions";
import { AuthorizationComponents } from "../../../../constants/authorization/views";
import { useAuthorization } from "../../providers/AuthorizationProvider/hooks";

export interface IAuthorizationRestrictorProps {
  actions?: AuthorizationActions[];
  redirectTo?: AuthorizationComponents;
  adminRole?: boolean;
  systemAdmin?: boolean;
}

export function AuthorizationRestrictor(
  props: React.PropsWithChildren<IAuthorizationRestrictorProps>
) {
  const { children, actions = [], redirectTo, adminRole, systemAdmin } = props;
  const { canDoAction, canRedirectTo, isAdminRole, isSystemAdmin } =
    useAuthorization();

  if (adminRole) {
    if (!isAdminRole) {
      return null;
    }
  }

  if (systemAdmin) {
    if (!isSystemAdmin) {
      return null;
    }
  }

  if (redirectTo) {
    if (!canRedirectTo(redirectTo)) {
      return null;
    }
  }

  if (actions.length && !redirectTo) {
    // Check if all provided actions are valid for current user context
    const hasValidActionSet = actions.reduce((accum, action) => {
      return accum || canDoAction(action);
    }, false);

    // Return proper render based on context
    if (!hasValidActionSet) {
      return null;
    }
  }

  return <>{children}</>;
}
