import { all, call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleNPISagaFlow
 * A redux saga that fetch a single NPI from the NeedsMD NPI Endpoint.
 */

export const startFetchZipCodeListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.ZipCode.zipCodeList, { params }),
  onResponse: (response) => {
    const actions: any = [
      put(
        Actions.setAll(
          response.data.results.map((zc: any) => ({ ...zc, id: zc.zip_number }))
        )
      ),
    ];

    // if the server returns a message inside the response, we proceed to show it in the frontend
    if (response.data.message) {
      actions.push(
        put(
          Actions.setError({
            type: "FETCH_ZIP_CODE_LIST_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ZIP_CODE_LIST_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/zipCode",
  sagas: {
    fetchZipCodeList: startFetchZipCodeListSagaFlow,
  },
});
