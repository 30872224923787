import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const facilityList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/facility`, opts);

export const facilityCMSList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/facilitycms`, opts);

export const facilityType = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/facility_type`, opts);

export const createFacility = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post(`/facility`, data, opts);

export const updateFacility = (
  id: number,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`facility/${id}`, data, opts);

export const deleteFacility = (id: number, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/facility/${id}`, opts);
