import {
  CaseReducer,
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { createReduxNameSpace } from "./utils";
import {
  reducers as errorReducers,
  initialState as errorInitialState,
  getSelectors as getErrorSelectors,
} from "./slices/error";
import {
  reducers as networkReducers,
  initialState as networkInitialState,
  getSelectors as getNetworkSelectors,
} from "./slices/network";
import {
  reducers as paginationReducers,
  initialState as paginationInitialState,
  getSelectors as getPaginationSelectors,
} from "./slices/pagination";

import { CombinedAdapterSettings } from "./types";

export const createCoreEntityAdapter = <M>(
  entityName: string,
  entitySettings?: CombinedAdapterSettings<M>
) => {
  const { ...entityParams } = entitySettings || {};

  // Create new entity adapter
  const entityAdapter = createEntityAdapter<M>(entityParams);

  const initialState = entityAdapter.getInitialState({
    ...errorInitialState,
    ...networkInitialState,
    ...paginationInitialState,
  });

  const entityReducers = {
    addMany: entityAdapter.addMany as CaseReducer<
      typeof initialState,
      PayloadAction<M[]>
    >,
    addOne: entityAdapter.addOne as CaseReducer<
      typeof initialState,
      PayloadAction<M>
    >,
    removeAll: entityAdapter.removeAll as CaseReducer<typeof initialState>,
    removeMany: entityAdapter.removeMany as CaseReducer<
      typeof initialState,
      PayloadAction<EntityId[]>
    >,
    removeOne: entityAdapter.removeOne as CaseReducer<
      typeof initialState,
      PayloadAction<EntityId>
    >,
    setAll: entityAdapter.setAll as CaseReducer<
      typeof initialState,
      PayloadAction<M[]>
    >,
    updateMany: entityAdapter.updateMany as CaseReducer<
      typeof initialState,
      PayloadAction<Update<M>[]>
    >,
    updateOne: entityAdapter.updateOne as CaseReducer<
      typeof initialState,
      PayloadAction<Update<M>>
    >,
    upsertMany: entityAdapter.upsertMany as CaseReducer<
      typeof initialState,
      PayloadAction<M[]>
    >,
    upsertOne: entityAdapter.upsertOne as CaseReducer<
      typeof initialState,
      PayloadAction<M>
    >,
  };

  const reducers = {
    ...entityReducers,
    ...errorReducers,
    ...networkReducers,
    ...paginationReducers,
  };

  const slice = createSlice({
    name: createReduxNameSpace(entityName),
    initialState,
    reducers,
  });

  const { actions, reducer } = slice;

  const selectors = {
    getEntitySelectors: entityAdapter.getSelectors,
    getErrorSelectors,
    getNetworkSelectors,
    getPaginationSelectors,
  };

  return {
    actions,
    reducer,
    selectors,
  };
};
