import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";
import {
  ProviderTaxonomyContext,
  getProviderTaxonomyRoot,
  getProviderTaxonomyChild,
} from "../urls";

export const getProviderTaxonomyList = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(getProviderTaxonomyRoot(id), opts);

export const createProviderTaxonomy = (
  id: string,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(getProviderTaxonomyRoot(id), data, opts);

export const getSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) => ApiClient.get(getProviderTaxonomyChild(context), opts);

export const updateSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(getProviderTaxonomyChild(context), data, opts);

export const deleteSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) => ApiClient.delete(getProviderTaxonomyChild(context), opts);

export * as History from "./history";
