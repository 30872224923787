import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import NpiHistoryActions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";

/**
 * @name startFetchNpiHistoryFlow
 * A redux saga that fetches an Npi's history from the NeedsMD Provider Endpoint.
 */
export const startFetchNpiHistoryFlow = makeEntityActionSaga({
  onStart: () => put(NpiHistoryActions.setNetworkIdle()),
  onCall: ({ id, params }) => call<any>(API.NPI.History.npiHistory, id, params),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(NpiHistoryActions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(NpiHistoryActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      NpiHistoryActions.setError({
        type: "FETCH_NPI_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/npiHistory",
  sagas: {
    fetchNpiHistory: startFetchNpiHistoryFlow,
  },
});
