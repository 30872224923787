/* eslint-disable @typescript-eslint/naming-convention */
import { entityToMap, getArrayFromMap } from "../../utils/normalization";

export const normalizeProviderSet = (providers: any[]) => {
  const providerMap = new Map();
  const addressMap = new Map();
  const taxonomyMap = new Map();
  const providerTaxonomyMap = new Map();

  providers.forEach((provider) => {
    const newProvider = { ...provider, npi: provider.id };

    // Add addresses to AddressMap
    newProvider.addresses = provider.addresses.map((address: any) =>
      entityToMap(address, addressMap)
    );

    newProvider.taxonomies = provider.taxonomies.map(
      (providerTaxonomy: { taxonomy: any }) => {
        const newProviderTaxonomy = { ...providerTaxonomy };
        const { specialty_class } = providerTaxonomy.taxonomy;

        // Fill taxonomy on its corresponding map
        newProviderTaxonomy.taxonomy = entityToMap(
          {
            ...providerTaxonomy.taxonomy,
            specialty_class_name: specialty_class ? specialty_class.name : "",
            specialty_class_id: specialty_class ? specialty_class.id : "",
          },
          taxonomyMap
        );

        return entityToMap(newProviderTaxonomy, providerTaxonomyMap);
      }
    );

    entityToMap(newProvider, providerMap);
  });

  return {
    providers: getArrayFromMap(providerMap),
    addresses: getArrayFromMap(addressMap),
    taxonomies: getArrayFromMap(taxonomyMap),
    providerTaxonomy: getArrayFromMap(providerTaxonomyMap),
  };
};
