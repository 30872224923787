import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import ProviderHistoryActions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";
// import { normalizeProviderSet } from "../utils";

/**
 * @name startFetchProviderHistoryFlow
 * A redux saga that fetches a provider's history from the NeedsMD Provider Endpoint.
 */
export const startFetchProviderHistoryFlow = makeEntityActionSaga({
  onStart: () => put(ProviderHistoryActions.setNetworkIdle()),
  onCall: (context) =>
    call<any>(
      API.Provider.History.getProviderHistory,
      context.providerId,
      context.params
    ),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(ProviderHistoryActions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(ProviderHistoryActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderHistoryActions.setError({
        type: "FETCH_PROVIDER_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/providerHistory",
  sagas: {
    fetchProviderHistory: startFetchProviderHistoryFlow,
  },
});
