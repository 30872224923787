import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const npiCommentsList = (id: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/${id}/comment`, opts);

export const npiCommentCreate = (
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/npi/${npiId}/comment`, data, opts);

export const npiCommentRead = (npiId: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/comment/${npiId}`, opts);

export const npiCommentsUpdate = (
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/npi/comment/${id}`, data, opts);

export const npiCommentsDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/npi/comment/${id}`, opts);

export * as History from "./history";
