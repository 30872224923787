import * as React from "react";
import { AuthorizationActions } from "../../../../constants/authorization/actions";
import { AuthorizationMap } from "../../../../constants/authorization/authorization";
import {
  AuthorizationRoles,
  ADMIN_ROLES,
} from "../../../../constants/authorization/roles";
import { AuthorizationComponents } from "../../../../constants/authorization/views";
import { useAuthentication } from "../../../auth/providers/AuthProvider/hooks";
import { AuthorizationProviderContext } from "./context";

export interface IAuthorizationProviderProps {
  component: AuthorizationComponents;
}

export default function AuthorizationProvider(
  props: React.PropsWithChildren<IAuthorizationProviderProps>
) {
  const { children, component } = props;

  // Use AuthHook to get User Role
  const { user } = useAuthentication();
  const role = user?.role;

  // Perform operations over User state
  const RoleMap = role
    ? AuthorizationMap[role]
    : AuthorizationMap[AuthorizationRoles.DEFAULT];
  const ComponentMap =
    RoleMap[component] || RoleMap[AuthorizationComponents.DEFAULT];

  const canDoAction = (action: AuthorizationActions) => {
    return ComponentMap.includes(action);
  };

  const canRedirectTo = (redirectTo: AuthorizationComponents) => {
    const authorizationComponent = RoleMap[redirectTo];

    if (authorizationComponent) {
      return authorizationComponent.includes(AuthorizationActions.READ);
    }
    return false;
  };

  const isAdminRole = ADMIN_ROLES.includes(role);
  const isSystemAdmin = role === AuthorizationRoles.SYSTEM_ADMIN;

  // Assign result to Provider value
  const providedData = {
    actions: ComponentMap,
    authorizationMap: AuthorizationMap,
    canDoAction,
    canRedirectTo,
    component,
    role,
    isAdminRole,
    isSystemAdmin,
  };

  return (
    <AuthorizationProviderContext.Provider value={providedData}>
      {children}
    </AuthorizationProviderContext.Provider>
  );
}
