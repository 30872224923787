import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiHospitalList = (
  account: string,
  projectId: string,
  id: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${id}/hospital`,
    opts
  );

export const npiHospitalCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/hospital`,
    data,
    opts
  );

export const npiHospital = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${npiId}/hospital/${id}`,
    opts
  );

export const npiHospitalUpdate = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/hospital/${id}`,
    data,
    opts
  );

export const npiHospitalDelete = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `/account/${account}/project/${projectId}/provider/${npiId}/hospital/${id}`,
    opts
  );

export * as History from "./history";
