import { createCoreEntityAdapter } from "../../../utils/core";
import { STORE_NAMES } from "../../../constants";
import { INormalizedProviderHistory } from "./types";

export default createCoreEntityAdapter<INormalizedProviderHistory>(
  STORE_NAMES.PROVIDER_HISTORY,
  {
    // eslint-disable-next-line no-underscore-dangle
    selectId: (providerHistory) => providerHistory.__id__,
  }
);
