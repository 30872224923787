/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import { CoreEntityState } from "../../types";
import { initialState } from "./initialState";

/**
 * Sets a CoreEntity "Error" values based on given action payload.
 * @param state A Core-Entity state instance.
 * @param action A redux action instance.
 * @param {string} action.type A given type name for the error.
 * @param {string} action.message A given message string for the error.
 */
const setError: CaseReducer<CoreEntityState<any>, PayloadAction<any>> = (
  state,
  action
) => {
  if (!action) {
    return;
  }
  state.error.type = action.payload.type;
  state.error.message = action.payload.message;
};

/**
 * Resets CoreEntity "Error" values to their default state.
 * @param state A Core-Entity state instance.
 */
const unsetError: CaseReducer<CoreEntityState<any>, AnyAction> = (state) => {
  state.error = initialState.error;
};

export const reducers = {
  setError,
  unsetError,
};
