import React from "react";
import { Box } from "@mui/material";

import Logo from "../../../../assets/logo-color.svg";
import LogoGrey from "../../../../assets/logo-grey.svg";
import { ILogoComponent } from "./types";

/**
 * Renders the Logo of the application
 */
const LogoComponent = ({ setGrey, ...props }: ILogoComponent) => (
  <Box
    src={setGrey ? LogoGrey : Logo}
    component="img"
    sx={{ lineHeight: 0 }}
    alt="Needs MD logo"
    {...props}
  />
);

export * from "./types";
export default LogoComponent;
