import { isEmpty } from "lodash";
import { EntitySelectors, createSelector } from "@reduxjs/toolkit";
import Factory from "./factory";
import { IAddress } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.projectAddresses;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IAddress, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};

export const getAddressesByNPI = createSelector(
  (state: RootState, props: { id: number }) => props,
  (state: RootState) => Entity.selectAll(state),
  (props, addresses) =>
    addresses
      .filter((address) => address.npi === props.id)
      .map((address) => ({
        ...address,
        postal_code: !isEmpty(address.postal_code?.replace(" ", ""))
          ? address.postal_code
          : address.zip_code,
      }))
);

export default {
  Entity,
  Error,
  Network,
  getAddressesByNPI,
  // Custom,
};
