import { IEntityHistory } from "./types";

export const formatHistory = (historyField: IEntityHistory) => {
  const {
    created_at: createdAt,
    actor,
    source_type: sourceType,
  } = historyField;

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(createdAt));

  return `${actor.first_name} ${actor.last_name} - ${formattedDate} [${sourceType}]`;
};
