import {
  PutEffect,
  CallEffect,
  AllEffect,
  Effect,
  SelectEffect,
} from "redux-saga/effects";
import { AnyAction } from "@reduxjs/toolkit";

interface IMakeEntityActionSagaSettings {
  onSelect?: (context: any) => SelectEffect | AllEffect<SelectEffect>;
  onStart?: () => PutEffect;
  onCall?: (data: any, selection: any) => CallEffect | AllEffect<Effect> | null;
  onResponse?: (response: any, data: any, selection: any) => Effect | null;
  onAfter?: (data: any, response: any, selection: any) => Effect | null;
  onFinish?: () => PutEffect;
  onError?: (err: any) => PutEffect | null;
}

export const defaultSettings = {};

export const defaultAction: AnyAction = {
  type: "",
};

export const makeEntityActionSaga = (
  settings: IMakeEntityActionSagaSettings = defaultSettings
): any => {
  const {
    onSelect, // common use for state selections before process begins
    onStart, // common use for setNetworkIdle actions
    onCall, // common use for N9API calls
    onResponse, // common use for set responses to state
    onAfter, // common use for an extra put (remove action, redirect action)
    onFinish, // common use for setNetworkAvailable actions
    onError,
  } = { ...defaultSettings, ...settings };

  return function* startUpdateEntitySagaFlow(action = defaultAction): any {
    let selection = null;
    const { payload = {} } = action;

    const context = {
      ...payload,
    };

    if (onSelect) {
      selection = yield onSelect(context);
    }

    try {
      let updateResponse = null;

      if (onStart) {
        yield onStart();
      }

      if (onCall) {
        updateResponse = yield onCall(context, selection);
      }

      if (updateResponse && onResponse) {
        yield onResponse(updateResponse, context, selection);
      }

      if (onAfter) {
        yield onAfter(updateResponse, context, selection);
      }

      if (onFinish) {
        yield onFinish();
      }
    } catch (err) {
      if (err && onError) {
        yield onError(err);
      }
      if (onFinish) {
        yield onFinish();
      }
    }
  };
};

export default makeEntityActionSaga;
