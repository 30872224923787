import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchGroupsSagaFlow
 * A redux saga that fetch groups from the NeedsMD Group Endpoint.
 */
// TODO: Complete revision of this saga code.
export const startFetchGroupsSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, params }) =>
    call<any>(API.Project.Group.npiGroupList, account, projectId, id, {
      params,
    }),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_GROUP_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateGroupSagaFlow
 * A redux saga that create an group from the NeedsMD Group Endpoint.
 */
export const startCreateGroupSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, data, params }) =>
    call<any>(API.Project.Group.npiGroupCreate, account, projectId, id, data, {
      params,
    }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_GROUP_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateGroupSagaFlow
 * A redux saga that update an group from the NeedsMD Group Endpoint.
 */
export const startUpdateGroupSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, data, params }) =>
    call<any>(
      API.Project.Group.npiGroupUpdate,
      account,
      projectId,
      npiId,
      id,
      data,
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_GROUP_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteGroupSagaFlow
 * A redux saga that deletes an group from the NeedsMD Group Endpoint.
 */
export const startDeleteGroupSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, params }) =>
    call<any>(API.Project.Group.npiGroupDelete, account, projectId, npiId, id, {
      params,
    }),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_GROUP_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/group-project",
  sagas: {
    fetchProjectGroups: startFetchGroupsSagaFlow,
    createProjectGroup: startCreateGroupSagaFlow,
    updateProjectGroup: startUpdateGroupSagaFlow,
    deleteProjectGroup: startDeleteGroupSagaFlow,
  },
});
