import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchStateListSagaFlow
 * A redux saga that fetch state list from the NeedsMD State Endpoint.
 */

export const startFetchStateListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.State.stateList, { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_STATE_LIST_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/state",
  sagas: {
    StateSelectors: startFetchStateListSagaFlow,
  },
});
