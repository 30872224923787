import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiAddressList = (
  account: string,
  projectId: string,
  id: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${id}/address`,
    opts
  );

export const npiAddressCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/address`,
    data,
    opts
  );

export const npiAddress = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${npiId}/address/${id}`,
    opts
  );

export const npiAddressUpdate = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/address/${id}`,
    data,
    opts
  );

export const npiAddressDelete = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `/account/${account}/project/${projectId}/provider/${npiId}/address/${id}`,
    opts
  );

export * as History from "./history";
