import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const userList = (queries: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/user${queries}`, opts);

export const userCreate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/user", data, opts);

export const user = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/user/${id}`, opts);

export const userUpdate = (id: any, data: any, opts?: AxiosRequestConfig) =>
  ApiClient.put(`/user/${id}`, data, opts);

export const userDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/user/${id}`, opts);
