import { EntitySelectors } from "@reduxjs/toolkit";
import Factory from "./factory";
import { IRoster } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.rosters;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IRoster, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Pagination = Factory.selectors.getPaginationSelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};

export default {
  Entity,
  Pagination,
  Error,
  Network,
  // Custom,
};
