import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";
import { getProviderHistoryRoot } from "./urls";

export const getProviderHistory = (
  account: string,
  id: string,
  providerId: string,
  opts?: AxiosRequestConfig
) => ApiClient.get(getProviderHistoryRoot(account, id, providerId), opts);
