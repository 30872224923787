import { createSelector } from "@reduxjs/toolkit";

import { PaginationState, PaginationStateSlice } from "./types";

export interface PaginationSelectors<V> {
  getPaginationState: (state: V) => PaginationState;
  getPaginationPageSize: (state: V) => number;
  getPaginationCurrentPage: (state: V) => number;
  getPaginationTotal: (state: V) => number;
}

export function getSelectors(): PaginationSelectors<PaginationStateSlice>;
export function getSelectors<V>(
  selectState: (state: V) => PaginationStateSlice
): PaginationSelectors<V>;

export function getSelectors<V>(
  selectState?: (state: V) => PaginationStateSlice
): PaginationSelectors<PaginationStateSlice> {
  const getPaginationState = createSelector(
    (state: PaginationStateSlice) => state.pagination,
    (state) => state
  );

  const getPaginationPageSize = createSelector(
    [getPaginationState],
    (state: PaginationState) => state.pageSize
  );

  const getPaginationCurrentPage = createSelector(
    [getPaginationState],
    (state: PaginationState) => state.currentPage
  );

  const getPaginationTotal = createSelector(
    [getPaginationState],
    (state: PaginationState) => state.total
  );

  if (!selectState) {
    return {
      getPaginationState,
      getPaginationPageSize,
      getPaginationCurrentPage,
      getPaginationTotal,
    };
  }

  return {
    getPaginationState: createSelector(selectState, getPaginationState),
    getPaginationPageSize: createSelector(selectState, getPaginationPageSize),
    getPaginationCurrentPage: createSelector(
      selectState,
      getPaginationCurrentPage
    ),
    getPaginationTotal: createSelector(selectState, getPaginationTotal),
  } as never;
}
