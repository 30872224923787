import { take, fork } from "redux-saga/effects";

const createSagaActions = (name: string, sagas: any) => {
  const actionMap: any = {};

  Object.keys(sagas).forEach((key) => {
    actionMap[key] = (payload: any) => ({
      type: `${name}/${key}`,
      payload,
    });
  });

  return actionMap;
};

const getType = (action: any) => {
  const segments = action.type.split("/");

  return segments[segments.length - 1];
};

const createSagaModule = (config: any) => {
  const { sagas, name } = config;

  function* sagaListener(): any {
    while (true) {
      const action = yield take("*");
      const saga = sagas[getType(action)];
      if (saga) {
        yield fork(saga, action);
      }
    }
  }

  return {
    listener: sagaListener,
    actions: createSagaActions(name, sagas),
  };
};

export default createSagaModule;
