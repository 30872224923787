import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleUserSagaFlow
 * A redux saga that fetch a single User from the NeedsMD User Endpoint.
 */

export const startFetchSingleUserSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, params }) => call<any>(API.User.user, id, { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_SINGLE_USER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateUserSagaFlow
 * A redux saga that update a single User from the NeedsMD User Endpoint.
 */

export const startUpdateUserSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data, params }) =>
    call<any>(API.User.userUpdate, id, data, { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_USER_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/user",
  sagas: {
    fetchUser: startFetchSingleUserSagaFlow,
    updateUser: startUpdateUserSagaFlow,
  },
});
