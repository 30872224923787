export type ProviderTaxonomyContext = {
  account: string;
  id: string;
  providerId: string;
  taxonomyId: string;
};

export const ProviderRoot = (account: string, id: string) =>
  `/account/${account}/project/${id}`;

export const getProviderChild = (
  account: string,
  id: string,
  providerId: string
) => `${ProviderRoot(account, id)}/provider/${providerId}`;

export const getProviderHistoryRoot = (
  account: string,
  id: string,
  providerId: string
) => `${getProviderChild(account, id, providerId)}/history`;

export const getProviderTaxonomyRoot = (
  account: string,
  id: string,
  providerId: string
) => `${getProviderChild(account, id, providerId)}/taxonomy`;

export const getProviderTaxonomyChild = (context: ProviderTaxonomyContext) =>
  `${getProviderTaxonomyRoot(
    context.account,
    context.id,
    context.providerId
  )}/${context.taxonomyId}`;

export const getProviderTaxonomyChildHistory = (
  context: ProviderTaxonomyContext
) => `${getProviderTaxonomyChild(context)}/history`;
