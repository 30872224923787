import axios from "axios";
import {
  getTokenFromStorage,
  saveTokenIntoStorage,
  formatToken,
  isTokenValid,
  TOKEN_SYMBOL,
  TOKEN_EXPIRES_AT,
} from "../authentication";
import ENV_SETTINGS from "../../constants/environment";

let authInterceptorInstance: number | undefined;

const ApiClient = axios.create({
  baseURL: `${ENV_SETTINGS.API_ROOT}/api/`,
});

const canBypass = ["/auth/refresh", "/user/logout"];

export const createStartAuthInterceptor =
  (API: any, onInterceptorLogout: () => void) => () => {
    if (authInterceptorInstance === undefined) {
      authInterceptorInstance = ApiClient.interceptors.request.use(
        async (config) => {
          // Get Session Tokens from Storage
          const accessSession = getTokenFromStorage(TOKEN_SYMBOL.ACCESS);
          const refreshSession = getTokenFromStorage(TOKEN_SYMBOL.REFRESH);

          const requestConfig = { ...config };

          /**
           * If access token remains valid, set it as an "Authorization" header
           * for the outbounf request.
           */
          if (
            accessSession &&
            isTokenValid(accessSession.expiresAt) &&
            requestConfig.headers
          ) {
            requestConfig.headers.Authorization = `Bearer ${accessSession.token}`;
            return requestConfig;
          }

          /**
           * If the refresh token remains valid, check the request URL against
           * the bypass list and call "/refresh" to get a new access token (if
           * appropriate).
           */
          if (
            refreshSession &&
            isTokenValid(refreshSession.expiresAt) &&
            requestConfig.headers
          ) {
            // If can be bypassed, return config with no changes.
            if (canBypass.includes(config.url || "")) {
              return requestConfig;
            }

            // Request new access token
            const fetchResponse = await API.Auth.refresh({
              refresh: refreshSession.token,
            });

            if (fetchResponse) {
              const { access } = fetchResponse.data;

              // Store it on LocalStorage
              saveTokenIntoStorage(
                TOKEN_SYMBOL.ACCESS,
                formatToken(access, TOKEN_EXPIRES_AT.ACCESS)
              );

              // Set it as Authorization Header
              requestConfig.headers.Authorization = `Bearer ${access}`;
              return requestConfig;
            }
          }

          /**
           * If both tokens are invalid, attempt to cancel the request and
           * call the "onInterceptorLogout" handler.
           */
          onInterceptorLogout();
          throw new axios.Cancel(
            "Token is not available. Do login, please."
          ) as Error;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  };

/**
 * A function handler to unlink the Axios Client request's interceptor.
 */
export const stopAuthInterceptor = () => {
  if (authInterceptorInstance !== undefined) {
    ApiClient.interceptors.request.eject(authInterceptorInstance);
    authInterceptorInstance = undefined;
  }
};

export default ApiClient;
