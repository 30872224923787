import { Theme, createTheme } from "@mui/material";
import { SIZES } from "../../constants";

const { breakpoints } = createTheme();

const BaseComponentTheme: Theme["components"] = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        height: SIZES.LAYOUT.NAVBAR.HEIGHT,
        [breakpoints.down("md")]: {
          height: SIZES.LAYOUT.NAVBAR.HEIGHT_MD,
        },
        // boxShadow: "rgb(0 51 105 / 65%) 1px 1px 5px",
        backgroundColor: "white !important",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        height: SIZES.LAYOUT.NAVBAR.HEIGHT,
        [breakpoints.down("md")]: {
          height: SIZES.LAYOUT.NAVBAR.HEIGHT_MD,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        width: SIZES.LAYOUT.SIDEBAR.WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: SIZES.LAYOUT.SIDEBAR.WIDTH,
          boxSizing: "border-box",
        },
      },
    },
  },
};

export default BaseComponentTheme;
