import { grey } from "@mui/material/colors";
import { COLORS } from "../../constants";

export default {
  customBackground: {
    gray: grey[200],
    primary: COLORS.MAIN.PRIMARY,
    secondary: COLORS.MAIN.SECONDARY,
    tertiary: COLORS.MAIN.TERTIARY,
    gradient: "linear-gradient(90deg, #8EC5FC, #E0C3FC)",
    image:
      "url(https://cdn.pixabay.com/photo/2015/04/20/13/22/clouds-731260_960_720.jpg) 0% 0% / cover",
  },
  gradient: {
    transparent: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))",
    solidBlack10: "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
    solidBlack50: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
    solidWhite10:
      "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
    solidWhite50:
      "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
    bitoneBlue75:
      "linear-gradient(rgba(9, 9, 121, 0.75), rgba(0, 212, 255, 0.75))",
  },
  social: {
    facebookColor: "#4267B2",
    twitterColor: "#1DA1F2",
    linkedinColor: "#0077B5",
    instagramColor: "#3f729b",
    youtubeColor: "#C4302B",
  },
  appBar: {
    background: grey[50],
    color: COLORS.MAIN.PRIMARY,
  },
  footer: {
    background: grey[200],
    color: COLORS.MAIN.PRIMARY,
  },
  primary: {
    main: COLORS.MAIN.PRIMARY,
  },
  secondary: {
    main: COLORS.MAIN.SECONDARY,
  },
  tertiary: {
    main: COLORS.MAIN.TERTIARY,
  },
  dashboard: {
    sidebar: {
      background: COLORS.MAIN.PRIMARY,
      color: "white",
    },
    content: {
      background: "#3DBAD2",
    },
  },
};
