/* eslint-disable no-param-reassign */
import { EntityReducer } from "../../types";

/**
 * Sets "isFetching" from state to true.
 * @param state A Core-Entity state instance.
 */
const setNetworkIdle: EntityReducer = (state) => {
  state.network.isFetching = true;
};

/**
 * Sets "isFetching" from state to false.
 * @param state A Core-Entity state instance.
 */
const setNetworkAvailable: EntityReducer = (state) => {
  state.network.isFetching = false;
};

export const reducers = {
  setNetworkIdle,
  setNetworkAvailable,
};
