import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleNPISagaFlow
 * A redux saga that fetch a single NPI from the NeedsMD NPI Endpoint.
 */

export const startFetchSingleNpiSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, params }) => call<any>(API.NPI.npi, id, "", { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_SINGLE_NPI_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateNpiSagaFlow
 * A redux saga that update a single NPI from the NeedsMD NPI Endpoint.
 */

export const startUpdateNpiSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data, params }) =>
    call<any>(API.NPI.npiUpdate, id, data, { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_NPI_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/npi",
  sagas: {
    fetchNpi: startFetchSingleNpiSagaFlow,
    updateNpi: startUpdateNpiSagaFlow,
  },
});
