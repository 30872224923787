import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchHospitalsSagaFlow
 * A redux saga that fetch hospitals from the NeedsMD Hospital Endpoint.
 */
// TODO: Complete revision of this saga code.
export const startFetchHospitalsSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, params }) =>
    call<any>(
      API.Project.Hospital.npiHospitalList,
      account,
      projectId,
      id,
      "",
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_HOSPITAL_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateHospitalSagaFlow
 * A redux saga that create an hospital from the NeedsMD Hospital Endpoint.
 */
export const startCreateHospitalSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, data, params }) =>
    call<any>(
      API.Project.Hospital.npiHospitalCreate,
      account,
      projectId,
      id,
      {
        ...data,
        provider_id: id,
      },
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_HOSPITAL_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateHospitalSagaFlow
 * A redux saga that update an hospital from the NeedsMD Hospital Endpoint.
 */
export const startUpdateHospitalSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, data, params }) =>
    call<any>(
      API.Project.Hospital.npiHospitalUpdate,
      account,
      projectId,
      npiId,
      id,
      data,
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_HOSPITAL_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteHospitalSagaFlow
 * A redux saga that deletes an hospital from the NeedsMD Hospital Endpoint.
 */
export const startDeleteHospitalSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, params }) =>
    call<any>(
      API.Project.Hospital.npiHospitalDelete,
      account,
      projectId,
      npiId,
      id,
      "",
      {
        params,
      }
    ),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_HOSPITAL_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/hospital-project",
  sagas: {
    fetchProjectHospitals: startFetchHospitalsSagaFlow,
    createProjectHospital: startCreateHospitalSagaFlow,
    updateProjectHospital: startUpdateHospitalSagaFlow,
    deleteProjectHospital: startDeleteHospitalSagaFlow,
  },
});
