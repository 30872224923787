import { all, call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import TaxonomyAction from "../projectTaxonomy/actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import { normalizeProviderTaxonomySet } from "./utils";

/**
 * @name startFetchProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchProviderTaxonomiesSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, id, projectId, config }) =>
    call<any>(
      API.Project.TaxonomyProvider.getProviderTaxonomyList,
      account,
      projectId,
      id,
      config
    ),
  onResponse: (response) => {
    const { providerTaxonomies, taxonomies } = normalizeProviderTaxonomySet(
      response.data.results
    );
    return all([
      put(Actions.upsertMany(providerTaxonomies)),
      put(TaxonomyAction.upsertMany(taxonomies)),
    ]);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateProviderTaxonomySagaFlow
 * A redux saga that creates a provider taxonomy for a provider from the NeedsMD Provider Endpoint.
 */
export const startCreateProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, providerId, data, config }) =>
    all([
      call<any>(
        API.Project.TaxonomyProvider.createProviderTaxonomy,
        account,
        projectId,
        providerId,
        data,
        config
      ),
    ]),
  onResponse: (response, { account, projectId, providerId }) =>
    all([
      put(Actions.upsertOne(response[0].data.data)),
      call(startFetchProviderTaxonomiesSagaFlow, {
        payload: { account, projectId, id: providerId },
      }),
    ]),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteProviderTaxonomySagaFlow
 * A redux saga that deletes a taxonomy from a provider from the NeedsMD Provider Endpoint.
 */
export const startDeleteProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId: id, providerId, taxonomyId, config }) =>
    call<any>(
      API.Project.TaxonomyProvider.deleteSingleProviderTaxonomy,
      { account, id, providerId, taxonomyId },
      config
    ),
  onResponse: (response, context) => put(Actions.removeOne(context.taxonomyId)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "DELETE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProviderTaxonomySagaFlow
 * A redux saga that updates a provider taxonomy for a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({
    account,
    projectId: id,
    providerId,
    providerTaxonomyId,
    data,
    config,
  }) =>
    all([
      call<any>(
        API.Project.TaxonomyProvider.updateSingleProviderTaxonomy,
        { account, id, providerId, taxonomyId: providerTaxonomyId },
        data,
        config
      ),
      call<any>(API.Taxonomy.getSingleTaxonomy, data.taxonomy),
    ]),
  onResponse: (response, { account, projectId, providerId }) =>
    all([
      put(Actions.upsertOne(response[0].data.data)),
      call(startFetchProviderTaxonomiesSagaFlow, {
        payload: { account, projectId, id: providerId },
      }),
    ]),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@needsmd/project-provider-taxonomy",
  sagas: {
    fetchProjectProviderTaxonomies: startFetchProviderTaxonomiesSagaFlow,
    createProjectProviderTaxonomy: startCreateProviderTaxonomySagaFlow,
    updateProjectProviderTaxonomy: startUpdateProviderTaxonomySagaFlow,
    deleteProjectProviderTaxonomy: startDeleteProviderTaxonomySagaFlow,
  },
});
