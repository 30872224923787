import * as React from "react";
import { Provider } from "./context";
import { IMessage } from "./types";

export interface IMessageCenterProps {}

export function MessageCenter(
  props: React.PropsWithChildren<IMessageCenterProps>
) {
  const [messages, setMessages] = React.useState<IMessage[]>([]);
  const { children } = props;

  /**
   * Function that add a new message to the message list.
   */
  const onAddMessage = (message: IMessage) => {
    setMessages([...messages, message]);
  };

  /**
   * Function that add a new message to the message list.
   */
  const onRemoveMessage = (messageId: IMessage["id"]) => {
    const filteredMessages = messages.filter(
      (message) => message.id !== messageId
    );
    setMessages(filteredMessages);
  };

  return (
    <Provider
      value={{
        messages,
        onAddMessage,
        onRemoveMessage,
      }}
    >
      {children}
    </Provider>
  );
}

export default MessageCenter;
