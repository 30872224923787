import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import ProviderActions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startDownloadReportSagaFlow
 * A redux saga that export the providers export from the NeedsMD Provider Endpoint.
 */
export const startDownloadReportSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, id, params, assessment }) =>
    call<any>(API.Project.downloadReport, account, id, assessment, { params }),
  onResponse: (response, { name }) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}-report.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    return null;
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "EXPORT_DOWNLOAD_REPORT_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/projectDownloadReport",
  sagas: {
    downloadReport: startDownloadReportSagaFlow,
  },
});
