import { AuthorizationRoles } from "./roles";
import { AuthorizationActions } from "./actions";
import { AuthorizationComponents } from "./views";

export type IAuthorizationMap = {
  [x: string]: {
    [y: string]: AuthorizationActions[];
  };
};

const ALL_ACTIONS = [
  AuthorizationActions.CREATE,
  AuthorizationActions.READ,
  AuthorizationActions.UPDATE,
  AuthorizationActions.DELETE,
];

export const AuthorizationMap: IAuthorizationMap = {
  [AuthorizationRoles.DEFAULT]: {
    [AuthorizationComponents.DEFAULT]: [AuthorizationActions.READ],
  },
  [AuthorizationRoles.CLIENT_USER]: {
    [AuthorizationComponents.DEFAULT]: [AuthorizationActions.READ],
    [AuthorizationComponents.DASHBOARD]: [AuthorizationActions.READ],
    [AuthorizationComponents.USER_PROFILE]: ALL_ACTIONS,
    [AuthorizationComponents.MANAGE_ACCOUNT]: [],
    [AuthorizationComponents.MANAGE_USER]: [],
    [AuthorizationComponents.MASTER_PROVIDER]: [AuthorizationActions.READ],
    [AuthorizationComponents.PROVIDER]: [AuthorizationActions.READ],
    [AuthorizationComponents.ROSTER_HOSPITAL]: ALL_ACTIONS,
    [AuthorizationComponents.ASSESSMENT_IN_PROGRESS]: ALL_ACTIONS,
  },
  [AuthorizationRoles.SYSTEM_ADMIN]: {
    [AuthorizationComponents.DEFAULT]: ALL_ACTIONS,
    [AuthorizationComponents.DASHBOARD]: ALL_ACTIONS,
    [AuthorizationComponents.USER_PROFILE]: ALL_ACTIONS,
    [AuthorizationComponents.MANAGE_ACCOUNT]: ALL_ACTIONS,
    [AuthorizationComponents.MANAGE_USER]: ALL_ACTIONS,
    [AuthorizationComponents.MASTER_PROVIDER]: ALL_ACTIONS,
    [AuthorizationComponents.PROVIDER]: ALL_ACTIONS,
    [AuthorizationComponents.ROSTER_HOSPITAL]: ALL_ACTIONS,
    [AuthorizationComponents.ASSESSMENT_IN_PROGRESS]: ALL_ACTIONS,
  },
  [AuthorizationRoles.SUPER_ADMIN]: {
    [AuthorizationComponents.DEFAULT]: [AuthorizationActions.READ],
    [AuthorizationComponents.DASHBOARD]: [AuthorizationActions.READ],
    [AuthorizationComponents.USER_PROFILE]: ALL_ACTIONS,
    [AuthorizationComponents.MANAGE_ACCOUNT]: [AuthorizationActions.READ],
    [AuthorizationComponents.MANAGE_USER]: [AuthorizationActions.READ],
    [AuthorizationComponents.MASTER_PROVIDER]: [AuthorizationActions.READ],
    [AuthorizationComponents.PROVIDER]: [AuthorizationActions.READ],
    [AuthorizationComponents.ROSTER_HOSPITAL]: [AuthorizationActions.READ],
    [AuthorizationComponents.ASSESSMENT_IN_PROGRESS]: [
      AuthorizationActions.READ,
    ],
  },
};
