import { all, call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchProjectSummaryClass
 * A redux saga that fetch groups from the NeedsMD Group Endpoint.
 */
// TODO: Complete revision of this saga code.
export const startFetchProjectSummaryClass = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ projectId, groupId, params }) =>
    call<any>(API.Project.SummaryClass.fetchList, projectId, groupId, {
      params,
    }),
  onResponse: (response, { includePagination = true }) => {
    const actions: any = [put(Actions.upsertMany(response.data.results))];
    if (includePagination) {
      actions.push(put(Actions.setPagination(response.data)));
    }

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_SUMMARY_CLASS_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/projectSummaryClass",
  sagas: {
    fetchList: startFetchProjectSummaryClass,
  },
});
