import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";
import {
  getProviderTaxonomyChildHistory,
  ProviderTaxonomyContext,
} from "../urls";

export const getProviderTaxonomyHistory = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) => ApiClient.get(getProviderTaxonomyChildHistory(context), opts);
