import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const npiList = (queries: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi${queries}`, opts);

export const npiCreate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/npi", data, opts);

export const npi = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/${id}`, opts);

export const npiUpdate = (id: any, data: any, opts?: AxiosRequestConfig) =>
  ApiClient.put(`/npi/${id}`, data, opts);

export const npiDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/npi/${id}`, opts);

export * as History from "./history";
