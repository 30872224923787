import { createSelector, EntitySelectors } from "@reduxjs/toolkit";
import Factory from "./factory";
import { IState } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.states;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IState, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};

export const selectValidStates = createSelector(Entity.selectAll, (states) => {
  const selectStates = states.filter((state) => state.population !== 0);

  return selectStates;
});

export const totalUSAPopulation = createSelector(Entity.selectAll, (states) => {
  let total = 0;
  states.forEach((state) => {
    total += state.population || 0;
  });

  return total;
});

export default {
  Entity,
  Error,
  Network,
  // Custom,
  totalUSAPopulation,
};
