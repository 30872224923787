/* eslint-disable no-param-reassign */
import { EntityReducer, EntityReducerWithPayloadAction } from "../../types";
import { initialState } from "./initialState";
import { SetPaginationPayload } from "./types";
/**
 * A reducer to set the pagination size.
 * @param state Redux slice state instance.
 * @param action Redux slice action instance.
 * @param action.pageSize The number of items that will exist per page.
 */
const setPagination: EntityReducerWithPayloadAction<SetPaginationPayload> = (
  state,
  action
) => {
  if (!action) {
    throw new Error("[Redux] Missing action in Pagination Action!");
  }

  if (state.pagination && action.payload?.page_size) {
    state.pagination.pageSize = action.payload.page_size;
    state.pagination.currentPage = action.payload.page;
    state.pagination.total = action.payload.total;
  }
};

/**
 * A reducer to set the pagination size.
 * @param state Redux slice state instance.
 * @param action Redux slice action instance.
 * @param action.pageSize The number of items that will exist per page.
 */
const resetPagination: EntityReducer = (state) => {
  if (state.pagination) {
    state.pagination = initialState.pagination;
  }
};

export const reducers = {
  setPagination,
  resetPagination,
};
