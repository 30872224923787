import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const npiAddressList = (
  id: string,
  queries: string,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/npi/${id}/address${queries}`, opts);

export const npiAddressCreate = (
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/npi/${npiId}/address`, data, opts);

export const npiAddress = (npiId: any, id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/${npiId}/address/${id}`, opts);

export const npiAddressUpdate = (
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/npi/address/${id}`, data, opts);

export const npiAddressDelete = (
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) => ApiClient.delete(`/npi/address/${id}`, opts);

export * as History from "./history";
