import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleFacilitySagaFlow
 * A redux saga that fetch a single Facility from the NeedsMD Facility Endpoint.
 */

export const startFetchFacilityListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.Facility.facilityList, { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_FACILITY_LIST_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateFacilitySagaFlow
 * A redux saga that create a single Facility from the NeedsMD Facility Endpoint.
 */

export const startCreateFacilitySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ data }) => call<any>(API.Facility.createFacility, data),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_FACILITY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateFacilitySagaFlow
 * A redux saga that update a single Facility from the NeedsMD Facility Endpoint.
 */

export const startUpdateFacilitySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data }) => call<any>(API.Facility.updateFacility, id, data),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_FACILITY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteFacilitySagaFlow
 * A redux saga that delete a single Facility from the NeedsMD Facility Endpoint.
 */

export const startDeleteFacilitySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id }) => call<any>(API.Facility.deleteFacility, id),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_FACILITY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/facility",
  sagas: {
    fetchFacilityList: startFetchFacilityListSagaFlow,
    createFacility: startCreateFacilitySagaFlow,
    updateFacility: startUpdateFacilitySagaFlow,
    deleteFacility: startDeleteFacilitySagaFlow,
  },
});
