import React from "react";
import { startCase } from "lodash";
import { useLocation } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";

import IconPath from "./iconPath";
import LogoComponent from "../LogoComponent";
import ROUTES from "../../../../constants/routes";

const defaultMessage = (name: string) =>
  `Wait a moment while we requesting the ${name} page...`;

const getPageName = (pathname: string) => {
  switch (pathname) {
    case ROUTES.ROOT:
      return "Home";
    case ROUTES.MARKETMAP:
      return "Visualize your Market";
    case ROUTES.HOSPITALROSTER:
      return "Hospital Roster";
    case ROUTES.ASSESSMENTINPROGRESS:
      return "Assessment in Progress";
    case ROUTES.USERLIST:
      return "User Management";
    case ROUTES.ACCOUNTLIST:
      return "Account Management";
    case ROUTES.MASTERPROVIDERLIST:
      return "Provider Management";
    default:
      return startCase(pathname.replace("/", ""));
  }
};

const FallBack = ({ message }: { message?: string }) => {
  const location = useLocation();

  return (
    // <motion.main
    //   initial={{ width: 0 }}
    //   animate={{ width: "100%" }}
    //   exit={{ x: "100%", opacity: 0 }}
    //   transition={{ duration: 1, type: "spring", stiffness: 100 }}
    // >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
        width: "100%",
        height: "92vh",
        background: "#FFFFFF",
      }}
    >
      <LogoComponent sx={{ width: "200px" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          color: "#FFFFFF",
        }}
      >
        <motion.div
          style={{
            width: 150,
            height: 150,
            borderRadius: 30,
            backgroundColor: "#3DBAD2",
          }}
          animate={{ rotate: 360 }}
          transition={{ ease: "linear", duration: 4, repeat: Infinity }}
        />
        <CircularProgress
          size="6rem"
          color="inherit"
          sx={{ position: "absolute" }}
        />
        <IconPath pathname={location.pathname} />
      </Box>

      <motion.div
        initial={{ y: 310 }}
        animate={{ y: 0 }}
        transition={{
          ease: [0.6, 0.01, -0.05, 0.9],
          duration: 1,
          repeat: Infinity,
          repeatDelay: 5,
        }}
      >
        <Typography variant="h5" sx={{ color: "gray" }}>
          {message || defaultMessage(getPageName(location.pathname))}
        </Typography>
      </motion.div>
    </Box>
    // </motion.main>
  );
};

export default FallBack;
