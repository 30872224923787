import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchDischargesListSagaFlow
 * A redux saga that fetch the discharges list from the NeedsMD NPI Endpoint.
 */

export const startFetchDischargesListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, user, params }) =>
    call<any>(API.Discharges.dischargeList, account, user, { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_DISCHARGES_LIST_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateDischargeSagaFlow
 * A redux saga that creates a discharge from the NeedsMD NPI Endpoint.
 */

export const startCreateDischargeSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, user, data }) =>
    call<any>(API.Discharges.createDischarge, account, user, data),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_DISCHARGE_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/discharge",
  sagas: {
    fetchDischargeList: startFetchDischargesListSagaFlow,
    createDischarge: startCreateDischargeSagaFlow,
  },
});
