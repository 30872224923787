import { createSelector } from "@reduxjs/toolkit";

import { ErrorState, ErrorStateSlice } from "./types";

export interface ErrorSelectors<V> {
  getErrorState: (state: V) => ErrorState;
  getErrorMessage: (state: V) => string | null;
  getErrorType: (state: V) => string | null;
}

export function getSelectors(): ErrorSelectors<ErrorStateSlice>;
export function getSelectors<V>(
  selectState: (state: V) => ErrorStateSlice
): ErrorSelectors<V>;

export function getSelectors<V>(
  selectState?: (state: V) => ErrorStateSlice
): ErrorSelectors<ErrorStateSlice> {
  const getErrorState = createSelector(
    (state: ErrorStateSlice) => state.error,
    (state) => state
  );

  const getErrorMessage = createSelector(
    [getErrorState],
    (errorState) => errorState.message
  );

  const getErrorType = createSelector(
    [getErrorState],
    (errorState) => errorState.type
  );

  if (!selectState) {
    return {
      getErrorState,
      getErrorMessage,
      getErrorType,
    };
  }

  return {
    getErrorState: createSelector(selectState, getErrorState),
    getErrorMessage: createSelector(selectState, getErrorMessage),
    getErrorType: createSelector(selectState, getErrorType),
  } as never;
}
