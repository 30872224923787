import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import { startFetchProvidersSagaFlow } from "../projectProvider/sagas";

/**
 * @name startFetchRolesSagaFlow
 * A redux saga that fetch roles from the NeedsMD Role Endpoint.
 */
// TODO: Complete revision of this saga code.
export const startFetchRolesSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, params }) =>
    call<any>(API.Project.Role.npiRoleList, account, projectId, id, {
      params,
    }),
  onResponse: (response, { id }) =>
    put(Actions.upsertOne({ ...response.data, id })),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ROLE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateRoleSagaFlow
 * A redux saga that create an role from the NeedsMD Role Endpoint.
 */
export const startCreateRoleSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, data, params }) =>
    call<any>(API.Project.Role.npiRoleCreate, account, projectId, id, data, {
      params,
    }),
  onAfter: (response, { account, projectId, npiId }) =>
    call(startFetchProvidersSagaFlow, {
      payload: {
        account,
        id: projectId,
        includeClean: false,
        ignorePagination: true,
        params: { id: npiId },
      },
    }),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_ROLE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateRoleSagaFlow
 * A redux saga that update an role from the NeedsMD Role Endpoint.
 */
export const startUpdateRoleSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, data, params }) =>
    call<any>(
      API.Project.Role.npiRoleUpdate,
      account,
      projectId,
      npiId,
      id,
      data,
      {
        params,
      }
    ),
  onAfter: (response, { account, projectId, npiId }) =>
    call(startFetchProvidersSagaFlow, {
      payload: {
        account,
        id: projectId,
        includeClean: false,
        ignorePagination: true,
        params: { id: npiId },
      },
    }),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_ROLE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteRoleSagaFlow
 * A redux saga that deletes an role from the NeedsMD Role Endpoint.
 */
export const startDeleteRoleSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, params }) =>
    call<any>(API.Project.Role.npiRoleDelete, account, projectId, npiId, id, {
      params,
    }),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_ROLE_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/role-project",
  sagas: {
    fetchProjectRoles: startFetchRolesSagaFlow,
    createProjectRole: startCreateRoleSagaFlow,
    updateProjectRole: startUpdateRoleSagaFlow,
    deleteProjectRole: startDeleteRoleSagaFlow,
  },
});
