import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const npiGroupList = (
  id: string,
  queries: string,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/provider/${id}/group${queries}`, opts);

export const npiGroupCreate = (
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/provider/${npiId}/group`, data, opts);

export const npiGroup = (npiId: any, id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/provider/${npiId}/group/${id}`, opts);

export const npiGroupUpdate = (
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/provider/${npiId}/group/${id}`, data, opts);

export const npiGroupDelete = (
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) => ApiClient.delete(`/provider/${npiId}/group/${id}`, opts);

export * as History from "./history";
