import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const RolesList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/role`, opts);

export const npiRoleList = (id: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/${id}/role`, opts);

export const npiRoleCreate = (
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/npi/${npiId}/role`, data, opts);

export const npiRole = (npiId: any, id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/npi/${npiId}/role/${id}`, opts);

export const npiRoleUpdate = (id: any, data: any, opts?: AxiosRequestConfig) =>
  ApiClient.put(`/npi/role/${id}`, data, opts);

export const npiRoleDelete = (npiId: any, id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/npi/${npiId}/role/${id}`, opts);

export * as History from "./history";
