import { configureStore } from "@reduxjs/toolkit";
import sagaMiddleware from "../services/reduxSaga";
import StoreReducer from "./reducer";

const store = configureStore({
  reducer: StoreReducer,
  devTools: true,
  middleware: [sagaMiddleware],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
