import { all } from "redux-saga/effects";
import { SagaManager as AccountSagaManager } from "../../redux/modules/account";
import { SagaManager as AddressSagaManager } from "../../redux/modules/address";
import { SagaManager as AddressHistorySagaManager } from "../../redux/modules/address/history";
import { SagaManager as AssessmentSagaManager } from "../../redux/modules/assessment";
import { SagaManager as CountySagaManager } from "../../redux/modules/county";
import { SagaManager as DischargeSagaManager } from "../../redux/modules/discharges";
import { SagaManager as FacilitySagaManager } from "../../redux/modules/facility";
import { SagaManager as GroupSagaManager } from "../../redux/modules/group";
import { SagaManager as GroupHistorySagaManager } from "../../redux/modules/group/history";
import { SagaManager as HospitalSagaManager } from "../../redux/modules/hospital";
import { SagaManager as HospitalHistorySagaManager } from "../../redux/modules/hospital/history";
import { SagaManager as NpiCommentManager } from "../../redux/modules/comment";
import { SagaManager as NpiCommentHistoryManager } from "../../redux/modules/comment/history";
import { SagaManager as NpiSagaManager } from "../../redux/modules/npi";
import { SagaManager as NpiHistorySagaManager } from "../../redux/modules/npi/history";
import { SagaManager as ProviderExportSagaManager } from "../../redux/modules/providerExport";
import { SagaManager as ProviderRoleSagaManager } from "../../redux/modules/providerRole";
import { SagaManager as ProjectAddressSagaManager } from "../../redux/modules/projectAddress";
import { SagaManager as ProjectCommentSagaManager } from "../../redux/modules/projectComment";
import { SagaManager as ProjectGroupSagaManager } from "../../redux/modules/projectGroup";
import { SagaManager as ProjectHospitalSagaManager } from "../../redux/modules/projectHospital";
import { SagaManager as ProjectProviderSagaManager } from "../../redux/modules/projectProvider";
import { SagaManager as ProjectRoleSagaManager } from "../../redux/modules/projectProviderRole";
import { SagaManager as ProjectProviderExportSagaManager } from "../../redux/modules/projectProviderExport";
import { SagaManager as ProjectReportDownloadSagaManager } from "../../redux/modules/projectReport";
import { SagaManager as ProjectProviderTaxonomySagaManager } from "../../redux/modules/projectProviderTaxonomy";
import { SagaManager as ProjectSummaryClassSagaManager } from "../../redux/modules/projectSumaryClass";
import { SagaManager as ProjectTaxonomySagaManager } from "../../redux/modules/projectTaxonomy";
import { SagaManager as ProviderHistorySagaManager } from "../../redux/modules/provider/history";
import { SagaManager as ProviderSagaManager } from "../../redux/modules/provider";
import { SagaManager as ProviderTaxonomySagaManager } from "../../redux/modules/providerTaxonomy";
import { SagaManager as ProviderTaxonomyHistorySagaManager } from "../../redux/modules/providerTaxonomy/history";
import { SagaManager as RosterSagaManager } from "../../redux/modules/roster";
import { SagaManager as StateSagaManager } from "../../redux/modules/state";
import { SagaManager as UserSagaManager } from "../../redux/modules/user";
import { SagaManager as ZipCodeSagaManager } from "../../redux/modules/zipCode";

/**
 * A generator function that initialize all the watcher sagas
 */
export default function* IndexSaga() {
  yield all([
    AccountSagaManager.listener(),
    AddressSagaManager.listener(),
    AddressHistorySagaManager.listener(),
    AssessmentSagaManager.listener(),
    CountySagaManager.listener(),
    DischargeSagaManager.listener(),
    FacilitySagaManager.listener(),
    GroupSagaManager.listener(),
    GroupHistorySagaManager.listener(),
    HospitalSagaManager.listener(),
    HospitalHistorySagaManager.listener(),
    NpiCommentManager.listener(),
    NpiCommentHistoryManager.listener(),
    NpiSagaManager.listener(),
    NpiHistorySagaManager.listener(),
    ProjectAddressSagaManager.listener(),
    ProjectCommentSagaManager.listener(),
    ProjectGroupSagaManager.listener(),
    ProjectHospitalSagaManager.listener(),
    ProjectProviderSagaManager.listener(),
    ProjectSummaryClassSagaManager.listener(),
    ProjectProviderTaxonomySagaManager.listener(),
    ProjectTaxonomySagaManager.listener(),
    ProviderExportSagaManager.listener(),
    ProviderRoleSagaManager.listener(),
    ProjectProviderExportSagaManager.listener(),
    ProjectReportDownloadSagaManager.listener(),
    ProjectRoleSagaManager.listener(),
    ProviderHistorySagaManager.listener(),
    ProviderSagaManager.listener(),
    ProviderTaxonomySagaManager.listener(),
    ProviderTaxonomyHistorySagaManager.listener(),
    RosterSagaManager.listener(),
    StateSagaManager.listener(),
    UserSagaManager.listener(),
    ZipCodeSagaManager.listener(),
  ]);
}
