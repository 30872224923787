import { combineReducers } from "@reduxjs/toolkit";
import AccountReducer from "./modules/account/reducer";
import AddressReducer from "./modules/address/reducer";
import AddressHistoryReducer from "./modules/address/history/reducer";
import AssessmentReducer from "./modules/assessment/reducer";
import CountyReducer from "./modules/county/reducer";
import DischargeReducer from "./modules/discharges/reducer";
import FacilityReducer from "./modules/facility/reducer";
import GroupReducer from "./modules/group/reducer";
import GroupHistoryReducer from "./modules/group/history/reducer";
import HistoryReducer from "./modules/history/reducer";
import HospitalReducer from "./modules/hospital/reducer";
import HospitalHistoryReducer from "./modules/hospital/history/reducer";
import NpiReducer from "./modules/npi/reducer";
import NpiHistoryReducer from "./modules/npi/history/reducer";
import ProjectAddressReducer from "./modules/projectAddress/reducer";
import ProjectCommentReducer from "./modules/projectComment/reducer";
import ProjectGroupReducer from "./modules/projectGroup/reducer";
import ProjectHospitalReducer from "./modules/projectHospital/reducer";
import ProjectProviderReducer from "./modules/projectProvider/reducer";
import ProjectProviderExportReducer from "./modules/projectProviderExport/reducer";
import ProjectDownloadReportReducer from "./modules/projectReport/reducer";
import ProjectProviderRoleReducer from "./modules/projectProviderRole/reducer";
import projectSumaryClass from "./modules/projectSumaryClass/reducer";
import ProjectTaxonomyReducer from "./modules/projectTaxonomy/reducer";
import ProjectProviderTaxonomyReducer from "./modules/projectProviderTaxonomy/reducer";
import ProviderCommentReducer from "./modules/comment/reducer";
import ProviderCommentHistoryReducer from "./modules/comment/history/reducer";
import ProviderExportReducer from "./modules/providerExport/reducer";
import ProviderRoleReducer from "./modules/providerRole/reducer";
import ProviderReducer from "./modules/provider/reducer";
import ProviderHistoryReducer from "./modules/provider/history/reducer";
import ProviderTaxonomyReducer from "./modules/providerTaxonomy/reducer";
import ProviderTaxonomyHistoryReducer from "./modules/providerTaxonomy/history/reducer";
import RosterReducer from "./modules/roster/reducer";
import StateReducer from "./modules/state/reducer";
import TaxonomyReducer from "./modules/taxonomy/reducer";
import UserReducer from "./modules/user/reducer";
import ZipCodeReducer from "./modules/zipCode/reducer";

export default combineReducers({
  accounts: AccountReducer,
  addresses: AddressReducer,
  addressesHistory: AddressHistoryReducer,
  assessments: AssessmentReducer,
  comments: ProviderCommentReducer,
  commentsHistory: ProviderCommentHistoryReducer,
  county: CountyReducer,
  discharges: DischargeReducer,
  facilities: FacilityReducer,
  hospitals: HospitalReducer,
  hospitalsHistory: HospitalHistoryReducer,
  histories: HistoryReducer,
  groups: GroupReducer,
  groupsHistory: GroupHistoryReducer,
  npi: NpiReducer,
  npiHistory: NpiHistoryReducer,
  projectAddresses: ProjectAddressReducer,
  projectComments: ProjectCommentReducer,
  projectGroups: ProjectGroupReducer,
  projectHospitals: ProjectHospitalReducer,
  projectProviders: ProjectProviderReducer,
  projectProviderExport: ProjectProviderExportReducer,
  projectDownloadReport: ProjectDownloadReportReducer,
  projectProviderRoles: ProjectProviderRoleReducer,
  projectSummaryClasses: projectSumaryClass,
  projectTaxonomies: ProjectTaxonomyReducer,
  projectProviderTaxonomies: ProjectProviderTaxonomyReducer,
  providerExport: ProviderExportReducer,
  providerRoles: ProviderRoleReducer,
  providerTaxonomies: ProviderTaxonomyReducer,
  providerTaxonomiesHistory: ProviderTaxonomyHistoryReducer,
  providers: ProviderReducer,
  providersHistory: ProviderHistoryReducer,
  rosters: RosterReducer,
  states: StateReducer,
  taxonomies: TaxonomyReducer,
  users: UserReducer,
  zipCodes: ZipCodeReducer,
});
