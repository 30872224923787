import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const providerList = (queries: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/provider${queries}`, opts);

export const providerCreate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/provider", data, opts);

export const providersUpdate = (opts?: AxiosRequestConfig) =>
  ApiClient.get("/provider", opts);

export const provider = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/provider/${id}`, opts);

export const providerUpdate = (id: any, data: any, opts?: AxiosRequestConfig) =>
  ApiClient.put(`/provider/${id}`, data, opts);

export const providerDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/provider/${id}`, opts);

export const providerTotal = (opts?: AxiosRequestConfig) =>
  ApiClient.get("/total/provider", opts);

export const privilegeList = (opts?: AxiosRequestConfig) =>
  ApiClient.get("/provider/privilege_status", opts);

export * as Taxonomy from "./taxonomy";
export * as History from "./history";
