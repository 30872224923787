import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";
import {
  getTokenFromStorage,
  TOKEN_SYMBOL,
} from "../../services/authentication";

// TODO: Improve this duped code implementation
export const login = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/auth/login", data, opts);

export const loginWrapped = async (data: any, opts?: AxiosRequestConfig) => {
  try {
    const response = await login(data, opts);
    return response;
  } catch (error) {
    // console.error(error);
    return false;
  }
};

export const logout = async (opts?: AxiosRequestConfig) => {
  // try {
  const refreshToken = getTokenFromStorage(TOKEN_SYMBOL.REFRESH);
  if (refreshToken) {
    await ApiClient.post(
      "/user/logout",
      { refresh_token: refreshToken.token },
      opts
    );
  }
  // } catch (error) {
  //   console.error(error);
  // }
};

export const refresh = async (data: any, opts?: AxiosRequestConfig) => {
  try {
    const response = await ApiClient.post("/auth/refresh", data, opts);
    return response;
  } catch (error) {
    // console.error(error);
    return false;
  }
};

export const requestResetEmail = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/auth/forgot", data, opts);

export const resetPassword = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.patch("/auth/reset", data, opts);
