import { PaletteMode } from "@mui/material";
import LightTheme, { PartialLightTheme } from "./themes/light";
import DarkTheme, { PartialDarkTheme } from "./themes/dark";

export const getStylesTheme = (mode: PaletteMode) => {
  if (mode === "light") {
    return LightTheme;
  }

  if (mode === "dark") {
    return DarkTheme;
  }

  throw new Error(`[Theme]: Unsupported theme ${mode}`);
};

export const getPartialTheme = (mode: PaletteMode) => {
  if (mode === "light") {
    return PartialLightTheme;
  }

  if (mode === "dark") {
    return PartialDarkTheme;
  }

  throw new Error(`[Theme]: Unsupported theme ${mode}`);
};
