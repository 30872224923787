import * as React from "react";
import { Alert, Snackbar, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useMessageCenter } from "../providers/MessageCenterProvider/hooks";

export interface IMessageComponentProps {}

const MessageComponent = () => {
  const { messages, onRemoveMessage } = useMessageCenter();

  if (messages.length === 0) {
    return null;
  }

  const { id, type, message, includeButton, onClick } = messages[0];
  const onCloseSnackbarAlert = () => {
    if (onClick) {
      onClick();
    }
    onRemoveMessage(id);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={messages.length > 0}
      autoHideDuration={includeButton ? null : 7000}
      onClose={onCloseSnackbarAlert}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      sx={{ minWidth: "65%" }}
    >
      <Alert
        severity={type ?? "information"}
        sx={{ width: "100%", overflowWrap: "anywhere" }}
        action={
          includeButton ? (
            <Button color="inherit" size="small" onClick={onCloseSnackbarAlert}>
              Close
            </Button>
          ) : (
            <IconButton onClick={onCloseSnackbarAlert}>
              <CloseIcon />
            </IconButton>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageComponent;
