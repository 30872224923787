import { createTheme } from "@mui/material";
import { merge } from "lodash";
import BaseTheme from "../base";
import palette from "./palette";
import components from "./components";

export const PartialLightTheme = merge(BaseTheme, {
  palette,
  components,
});

export default createTheme(PartialLightTheme);
