const ROOT = `/`;

export default {
  ROOT,
  ABOUT: `${ROOT}about`,
  LOGIN: `${ROOT}login`,
  CONTACT: `${ROOT}contact`,
  FORGOT: `${ROOT}forgot`,
  RESET: `${ROOT}reset`,
  REQUEST: `${ROOT}request`,
  ACCOUNTLIST: `${ROOT}accounts`,
  ACCOUNTNEW: `${ROOT}accounts/new`,
  ACCOUNT: `${ROOT}accounts/:id`,
  DATASOURCE: `${ROOT}data-sources`,
  USERLIST: `${ROOT}users`,
  USERNEW: `${ROOT}users/new`,
  USER: `${ROOT}users/:id`,
  MASTERPROVIDERLIST: `${ROOT}master/providers`,
  USERPROFILE: `${ROOT}profile`,
  PROVIDERLIST: `${ROOT}providers`,
  PROVIDERNEW: `${ROOT}providers/new`,
  PROVIDER: `${ROOT}providers/:id`,
  MARKETMAP: `${ROOT}market`,
  NATIONALSTATISTICS: `${ROOT}national-statistics`,
  HOSPITALROSTER: `${ROOT}roster`,
  HOSPITALROSTERINFO: `${ROOT}roster/:id`,
  ASSESSMENTINPROGRESS: `${ROOT}assessment`,
  ASSESSMENTINFO: `${ROOT}assessment/:id`,
  DASHBOARD: ROOT,
};
