import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const ProjectRoot = (account: number, id: number) =>
  `/account/${account}/project/${id}`;

export const providerList = (
  account: number,
  id: number,
  opts?: AxiosRequestConfig
) => ApiClient.get(`${ProjectRoot(account, id)}/provider`, opts);

export const rosterProviderList = (
  account: number,
  id: number,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/account/${account}/roster/${id}`, opts);

export const providerCreate = (
  account: number,
  id: number,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`${ProjectRoot(account, id)}/provider`, data, opts);

export const providersUpdate = (
  account: number,
  id: number,
  opts?: AxiosRequestConfig
) => ApiClient.get(`${ProjectRoot(account, id)}/provider`, opts);

export const provider = (
  account: number,
  id: number,
  providerId: number,
  opts?: AxiosRequestConfig
) => ApiClient.get(`${ProjectRoot(account, id)}/provider/${providerId}`, opts);

export const providerUpdate = (
  account: number,
  id: number,
  providerId: number,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `${ProjectRoot(account, id)}/provider/${providerId}`,
    data,
    opts
  );

export const providerDelete = (
  account: number,
  id: number,
  providerId: number,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(`${ProjectRoot(account, id)}/provider/${providerId}`, opts);

export const providerTotal = (opts?: AxiosRequestConfig) =>
  ApiClient.get("/total/provider", opts);

export const downloadReport = (
  account: number,
  id: number,
  assessment: number,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `${ProjectRoot(account, id)}/assessment/${assessment}/report`,
    opts
  );

export const providerDeleteFlag = (
  account: number,
  id: number,
  providerId: number,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `${ProjectRoot(account, id)}/provider/${providerId}/flag`,
    opts
  );

export * as Address from "./address";
export * as TaxonomyProvider from "./taxonomyProvider";
export * as History from "./history";
export * as Comment from "./comment";
export * as Group from "./group";
export * as Role from "./role";
export * as Hospital from "./hospital";
export * as SummaryClass from "./sumaryClass";
export * as FTEAdjusted from "./FTEAdjusted";
export * as ProviderDemographic from "./providerDemographic";
export * as CommunityNeeds from "./communityNeeds";
