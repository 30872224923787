export type ProviderTaxonomyContext = {
  providerId: string;
  taxonomyId: string;
};

export const ProviderRoot = "/provider";

export const getProviderChild = (providerId: string) =>
  `${ProviderRoot}/${providerId}`;

export const getProviderHistoryRoot = (providerId: string) =>
  `${getProviderChild(providerId)}/history`;

export const getProviderTaxonomyRoot = (providerId: string) =>
  `${getProviderChild(providerId)}/taxonomy`;

export const getProviderTaxonomyChild = (context: ProviderTaxonomyContext) =>
  `${getProviderTaxonomyRoot(context.providerId)}/${context.taxonomyId}`;

export const getProviderTaxonomyChildHistory = (
  context: ProviderTaxonomyContext
) => `${getProviderTaxonomyChild(context)}/history`;
