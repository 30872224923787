import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const accountList = (queries: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/account${queries}`, opts);

export const accountCreate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/account", data, opts);

export const account = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/account/${id}`, opts);

export const accountUpdate = (id: any, data: any, opts?: AxiosRequestConfig) =>
  ApiClient.put(`/account/${id}`, data, opts);

export const accountDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/account/${id}`, opts);
