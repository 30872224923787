import * as React from "react";
import { useNavigate } from "react-router-dom";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { SIZES } from "../../../../styles/constants";
import { AuthorizationRestrictor } from "../../../authorization/fragments/AuthorizationRestrictor";
import { AuthorizationComponents } from "../../../../constants/authorization/views";

export type MenuItem = {
  Icon: React.ElementType;
  label: string;
  path: string;
  authorizationComponent: AuthorizationComponents;
};

export interface ISideMenuBarProps extends DrawerProps {
  items: MenuItem[];
  onToggle: () => void;
}

const MenuItemList = ({ items }: any) => {
  const navigate = useNavigate();

  if (items.length === 0) {
    return null;
  }

  const menuItems = items.map(
    ({ Icon, label, path, authorizationComponent }: MenuItem) => [
      <AuthorizationRestrictor key={label} redirectTo={authorizationComponent}>
        <ListItem button key={label} onClick={() => navigate(path)}>
          <ListItemIcon sx={{ justifyContent: "center" }}>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
        <Divider key={`${label}-divider`} />
      </AuthorizationRestrictor>,
    ]
  );

  return <List>{menuItems}</List>;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIZES.LAYOUT.SIDEBAR.WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: SIZES.LAYOUT.SIDEBAR.WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function SideMenuBar(props: ISideMenuBarProps) {
  const { items, open, onToggle, ...rest } = props;

  return (
    <CustomDrawer variant="permanent" open={open} {...rest}>
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <MenuItemList items={items} />
        <ListItem button key="toggle" onClick={() => onToggle()}>
          <ListItemIcon sx={{ justifyContent: "center" }}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </ListItemIcon>
          <ListItemText primary="Toggle Sidebar" />
        </ListItem>
      </Box>
    </CustomDrawer>
  );
}
