import * as React from "react";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../fragments/LogoComponent";
import ROUTES from "../../../../constants/routes";

export interface IRestrictedAccessProps {}

export function RestrictedAccess(
  props: React.PropsWithChildren<IRestrictedAccessProps>
) {
  const { children } = props;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        backgroundColor: (theme: any) => theme.palette.customBackground.gray,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2em",
          backgroundColor: (theme) => theme.palette.grey["300"],
          boxShadow: (theme) => theme.shadows[2],
          borderRadius: "1em",
        }}
      >
        <Typography fontSize="8em" component="span" sx={{ lineHeight: 0 }}>
          <ErrorOutlineIcon fontSize="inherit" color="primary" />
        </Typography>
        <Typography color="primary" fontWeight="bold" fontSize="1.5em">
          Access Restricted
        </Typography>
        <Typography
          sx={{ mt: "1em", color: (theme) => theme.palette.grey["600"] }}
          fontWeight="400"
          fontSize="1em"
          variant="subtitle2"
        >
          You need permission to access this site. If you think this is an
          error, please contact your system administrator.
        </Typography>
        <Box sx={{ mt: "2em" }}>
          <Link to={ROUTES.ROOT}>
            <Logo sx={{ height: "3em" }} />
          </Link>
        </Box>
      </Box>
      {children}
    </Box>
  );
}
