export const LAYOUT = {
  NAVBAR: {
    HEIGHT: "5rem",
    HEIGHT_MD: "4rem",
  },
  SIDEBAR: {
    WIDTH: "15rem",
  },
};

export default {
  LAYOUT,
};
