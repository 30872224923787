import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";
import {
  ProviderTaxonomyContext,
  getProviderTaxonomyRoot,
  getProviderTaxonomyChild,
} from "../urls";

export const getProviderTaxonomyList = (
  account: string,
  id: any,
  providerId: any,
  opts?: AxiosRequestConfig
) => ApiClient.get(getProviderTaxonomyRoot(account, id, providerId), opts);

export const createProviderTaxonomy = (
  account: string,
  id: string,
  providerId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(getProviderTaxonomyRoot(account, id, providerId), data, opts);

export const getSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) => ApiClient.get(getProviderTaxonomyChild(context), opts);

export const updateSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(getProviderTaxonomyChild(context), data, opts);

export const deleteSingleProviderTaxonomy = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) => ApiClient.delete(getProviderTaxonomyChild(context), opts);

export * as History from "./history";
