import { EntitySelectors, createSelector } from "@reduxjs/toolkit";

import Factory from "./factory";
import { IGroup } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.projectSummaryClasses;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IGroup, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);
export const Pagination = Factory.selectors.getPaginationSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};
export const Custom = {
  getSummaryList: createSelector(
    (state: RootState) => Entity.selectAll(state),
    (list) => {
      return list.filter((data) => data.quantity);
    }
  ),
  getSummaryPage: createSelector(
    (state: RootState, props: { name: string }) => props,
    (state: RootState) => Entity.selectAll(state),
    (state: RootState) => Pagination.getPaginationPageSize(state),
    ({ name }, list, pageSize) => {
      const findIndexPage = list.findIndex((data) => data.name === name);

      return Math.ceil((findIndexPage + 1) / pageSize);
    }
  ),
};

export default {
  Entity,
  Error,
  Network,
  Custom,
};
