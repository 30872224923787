import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchRosterListSagaFlow
 * A redux saga that fetch the discharges list from the NeedsMD NPI Endpoint.
 */

export const startFetchRosterListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, params }) =>
    call<any>(API.Roster.rosterList, account, { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ROSTERS_LIST_ERROR",
        message: err.message,
      })
    ),
});
/**
 * @name startFetchRosterListSagaFlow
 * A redux saga that fetch the discharges list from the NeedsMD NPI Endpoint.
 */

export const startFetchRosterAdminListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.Roster.rosterAdminList, { params }),
  onResponse: (response) => {
    const actions: any = [
      put(Actions.setPagination(response.data)),
      put(Actions.setAll(response.data.results)),
    ];

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ROSTERS_LIST_ERROR",
        message: err.message,
      })
    ),
});

export const startUploadRosterListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ data }) => call<any>(API.Roster.rosterUpload, data),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPLOAD_ROSTER_ERROR",
        message: err.message,
      })
    ),
});
export default createSagaModule({
  name: "@intechideas/needsmd/roster",
  sagas: {
    fetchRosterList: startFetchRosterListSagaFlow,
    fetchRosterAdminList: startFetchRosterAdminListSagaFlow,
    uploadRoster: startUploadRosterListSagaFlow,
  },
});
