import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export type ProviderTaxonomyContext = {
  providerId: string;
  addressId: string;
};

export const npiAddressHistory = (
  context: ProviderTaxonomyContext,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/npi/${context.providerId}/address/${context.addressId}/history`,
    opts
  );
