import { EntitySelectors, createSelector } from "@reduxjs/toolkit";
import Factory from "./factory";
import { IAssessment } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.assessments;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IAssessment, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
export const Custom = {
  getAssessmentList: createSelector(
    (state: RootState) => Entity.selectAll(state),
    (assessments) => {
      return assessments.map((assessment) => ({
        user: assessment.actor,
        ...assessment,
        date: new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }).format(new Date(assessment.created_at)),
        last_update_usr: assessment.last_update_usr
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }).format(new Date(assessment.last_update_usr))
          : "",
        close_date: assessment.last_update_usr
          ? new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }).format(
              new Date(assessment.last_update_usr).setDate(
                new Date(assessment.last_update_usr).getDate() + 60
              )
            )
          : "",
        zip: assessment.data ? assessment.data.map((d) => d.zip) : [],
        status: assessment.status ? assessment.status : "unknown",
        projectId: assessment.project_id ? assessment.project_id[0] : null,
        facilityName: assessment.facility
          ? assessment.facility.name
          : "default",
      }));
    }
  ),
  getAssessmentById: createSelector(
    (state: RootState, props: { id: string }) => props,
    (state: RootState) => Entity.selectAll(state),
    (props, assessments) => {
      const assessment = assessments.find(
        (data) => String(data.id) === String(props.id)
      );

      if (!assessment) {
        return {};
      }

      return {
        user: assessment.actor,
        ...assessment,
        date: new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date(assessment.created_at)),
        zip: assessment.data ? assessment.data.map((d) => d.zip) : [],
        status: assessment.status ? assessment.status : "unknown",
        projectId: assessment.project_id ? assessment.project_id[0] : null,
        facilityName: assessment.facility
          ? assessment.facility.name
          : "default",
      };
    }
  ),
};

export default {
  Entity,
  Error,
  Network,
  // Custom,
};
