import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiFTEAdjustedList = (
  account: string,
  projectId: string,
  npiId: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${npiId}/fte`,
    opts
  );

export const npiFTEAdjustedCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/fte`,
    data,
    opts
  );

export const npiFTEAdjustedUpdate = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/fte/${id}`,
    data,
    opts
  );
