import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiRoleList = (
  account: string,
  projectId: string,
  id: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${id}/role`,
    opts
  );

export const npiRoleCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/role`,
    data,
    opts
  );

export const npiRole = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${npiId}/role/${id}`,
    opts
  );

export const npiRoleUpdate = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/role/${id}`,
    data,
    opts
  );

export const npiRoleDelete = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `/account/${account}/project/${projectId}/provider/${npiId}/role/${id}`,
    opts
  );

export * as History from "./history";
