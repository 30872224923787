import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const rosterList = (account: number, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/account/${account}/roster`, opts);

export const rosterAdminList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/roster`, opts);

export const rosterUpload = (data: any) =>
  ApiClient.post(`provider_file`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    baseURL: ApiClient.defaults.baseURL?.replace("api", "collector"),
  });
