import { Theme } from "@mui/material";
import { COLORS } from "../../constants";

const LightComponentTheme: Theme["components"] = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        // boxShadow: "rgb(0 51 105 / 65%) 1px 1px 5px",
        backgroundColor: "white !important",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: "#003366",
        boxShadow: "rgb(0 51 105 / 70%) 1px 1px 5px",
        color: "white",
      },
      root: {
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDivider-root": {
          borderColor: COLORS.MAIN.TERTIARY,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        boxShadow: "rgb(0 51 102 / 33%) 0px 2px 6px",
      },
    },
  },
};

export default LightComponentTheme;
