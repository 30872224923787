import { createSelector, EntitySelectors } from "@reduxjs/toolkit";

import Factory from "./factory";
import { INormalizedAddressHistory } from "./types";
import { RootState } from "../../../store";
import { Entity as UserEntitySelectors } from "../../user/selectors";
import { formatHistory } from "../../../utils/history";

export const getSlice = (state: RootState) => state.addressesHistory;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  INormalizedAddressHistory, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);
export const Pagination = Factory.selectors.getPaginationSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
export const Custom = {};

export const selectFormattedHistoryForId = createSelector(
  (state: RootState, id: string) => id,
  Entity.selectEntities,
  UserEntitySelectors.selectEntities,
  (id, providerHistoryEntityMap, userEntityMap) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __id__, ...historyEntity } = providerHistoryEntityMap[id] || {};

    if (historyEntity) {
      return Object.entries(historyEntity).reduce(
        (accum: any, [key, value]) => {
          return {
            ...accum,
            [key]: formatHistory({
              ...value,
              actor: userEntityMap[value.actor],
            }),
          };
        },
        {}
      );
    }

    return null;
  }
);

export default {
  Entity,
  Error,
  Network,
  Pagination,
  // Custom,
};
