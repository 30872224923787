import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const dischargeList = (
  account: number,
  user: number,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/account/${account}/user/${user}/discharges`, opts);

export const createDischarge = (
  account: number,
  user: number,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/account/${account}/user/${user}/discharges`, data, opts);

export const dischargeValidate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post(`/discharge`, data, opts);
