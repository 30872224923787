import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const dataSourceList = (queries: string, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/dataSource${queries}`, opts);

export const dataSourceCreate = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/dataSource", data, opts);

export const dataSource = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/dataSource/${id}`, opts);

export const dataSourceUpdate = (
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/dataSource/${id}`, data, opts);

export const dataSourceDelete = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/dataSource/${id}`, opts);
