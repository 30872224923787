import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchCountyListSagaFlow
 * A redux saga that fetch county list from the NeedsMD County Endpoint.
 */

export const startFetchCountyListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.County.countyList, { params }),
  onResponse: (response, { params }) =>
    put(
      Actions.upsertMany(
        response.data.results.map((s: any) => ({ ...s, state: params.state }))
      )
    ),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_COUNTY_LIST_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/county",
  sagas: {
    fetchCountyList: startFetchCountyListSagaFlow,
  },
});
