import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const npiHospitalList = (
  id: string,
  queries: string,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/provider/${id}/hospital${queries}`, opts);

export const npiHospitalCreate = (
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.post(`/provider/${npiId}/hospital`, data, opts);

export const npiHospital = (npiId: any, id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/provider/${npiId}/hospital/${id}`, opts);

export const npiHospitalUpdate = (
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/provider/${npiId}/hospital/${id}`, data, opts);

export const npiHospitalDelete = (
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) => ApiClient.delete(`/provider/${npiId}/hospital/${id}`, opts);

export * as History from "./history";
