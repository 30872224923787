import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import ProviderTaxonomyHistoryActions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";

/**
 * @name startFetchProviderTaxonomyHistoryFlow
 * A redux saga that fetches a provider's history from the NeedsMD Provider Endpoint.
 */
export const startFetchProviderTaxonomyHistoryFlow = makeEntityActionSaga({
  onStart: () => put(ProviderTaxonomyHistoryActions.setNetworkIdle()),
  onCall: ({ providerId, taxonomyId, params }) =>
    call<any>(
      API.Provider.Taxonomy.History.getProviderTaxonomyHistory,
      { providerId, taxonomyId },
      params
    ),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(ProviderTaxonomyHistoryActions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(ProviderTaxonomyHistoryActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderTaxonomyHistoryActions.setError({
        type: "FETCH_PROVIDER_TAXONOMY_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/providerTaxonomyHistory",
  sagas: {
    fetchProviderTaxonomyHistory: startFetchProviderTaxonomyHistoryFlow,
  },
});
