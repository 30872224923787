import { useContext } from "react";
import { MessageCenterContext } from "./context";

export const useMessageCenter = () => {
  const context = useContext(MessageCenterContext);
  if (!context) {
    throw new Error(
      '"useMessageCenter" should be called inside a "React" component!'
    );
  }

  return context;
};
