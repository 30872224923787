import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../../utils/sagas/toolkit";
import UserActions from "../../user/actions";
import HospitalActions from "./actions";
import { makeEntityActionSaga } from "../../../utils/sagas";
import API from "../../../../api";

/**
 * @name startFetchHospitalHistoryFlow
 * A redux saga that fetches an Hospital's history from the NeedsMD Provider Endpoint.
 */
export const startFetchHospitalHistoryFlow = makeEntityActionSaga({
  onStart: () => put(HospitalActions.setNetworkIdle()),
  onCall: ({ npiId, hospitalId, params }) =>
    call<any>(
      API.Hospital.History.npiHospitalHistory,
      npiId,
      hospitalId,
      params
    ),
  onResponse: (response) => {
    const { users, history } = response.data.data;

    const actions: any = [
      put(UserActions.upsertMany(users)),
      put(HospitalActions.upsertOne(history)),
    ];

    return all(actions);
  },
  onFinish: () => put(HospitalActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      HospitalActions.setError({
        type: "FETCH_HOSPITAL_HISTORY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/hospitalHistory",
  sagas: {
    fetchHospitalHistory: startFetchHospitalHistoryFlow,
  },
});
