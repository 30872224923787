import { EntitySelectors, createSelector } from "@reduxjs/toolkit";
import Factory from "./factory";
import { IComment } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.projectComments;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IComment, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
export const Custom = {
  getCommentsByNPI: createSelector(
    (state: RootState, props: { id: string }) => props,
    (state: RootState) => Entity.selectAll(state),
    (props, comments) =>
      comments.filter((comment) => String(comment.npi) === String(props.id))
  ),
};

export default {
  Entity,
  Error,
  Network,
  Custom,
};
