import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiCommentsList = (
  account: string,
  projectId: string,
  id: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${id}/comment`,
    opts
  );

export const npiCommentCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/comment`,
    data,
    opts
  );

export const npiCommentRead = (
  account: string,
  npiId: any,
  opts?: AxiosRequestConfig
) => ApiClient.get(`/npi/comment/${npiId}`, opts);

export const npiCommentsUpdate = (
  account: string,
  projectId: string,
  npiId: string,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/comment/${id}`,
    data,
    opts
  );

export const npiCommentsDelete = (
  account: string,
  projectId: string,
  npiId: string,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `/account/${account}/project/${projectId}/provider/${npiId}/comment/${id}`,
    opts
  );

export * as History from "./history";
