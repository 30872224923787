import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ROUTES from "../../../../constants/routes";
import { NavigationLayout } from "../../../layout/containers/NavigationLayout";
import { useAuthentication } from "../../../auth/providers/AuthProvider/hooks";
import { PrivateRoute } from "../PrivateView";
import { NotFound } from "../../../commons/status/NotFound";
import { AuthorizationRoles } from "../../../../constants/authorization/roles";
import FallBack from "../../../commons/fragments/FallBack";

const LandingPage = React.lazy(() => import("../../../views/LandingView"));
const AboutUs = React.lazy(() => import("../../../views/AboutUsView"));
const Login = React.lazy(() => import("../../../views/LoginView"));
const ForgotPassword = React.lazy(
  () => import("../../../views/ForgotPasswordView")
);
const ResetPassword = React.lazy(
  () => import("../../../views/ResetPasswordView")
);
const RequestDemo = React.lazy(() => import("../../../views/RequestDemoView"));
const ContactUs = React.lazy(() => import("../../../views/ContactUsView"));
const Dashboard = React.lazy(() => import("../../../views/DashboardView"));
const AccountList = React.lazy(() => import("../../../views/AccountListView"));
const AccountCreate = React.lazy(
  () => import("../../../views/AccountCreateView")
);
const AccountUpdate = React.lazy(
  () => import("../../../views/AccountUpdateView")
);
const AssessmentInProgress = React.lazy(
  () => import("../../../views/AssessmentInProgressView")
);
const AssessmentInfo = React.lazy(
  () => import("../../../views/AssessmentInfoView")
);
const DataSourceList = React.lazy(
  () => import("../../../views/DataSourceListView")
);
const HospitalRoster = React.lazy(
  () => import("../../../views/HospitalRosterView")
);
const HospitalRosterInfo = React.lazy(
  () => import("../../../views/HospitalRosterInfoView")
);
const MasterProviderList = React.lazy(
  () => import("../../../views/MasterProviderListView")
);
const UserList = React.lazy(() => import("../../../views/UserListView"));
const UserCreate = React.lazy(() => import("../../../views/UserCreateView"));
const UserUpdate = React.lazy(() => import("../../../views/UserUpdateView"));
const UserProfile = React.lazy(() => import("../../../views/ProfileView"));
const ProviderList = React.lazy(
  () => import("../../../views/ProviderListView")
);
const ProviderCreate = React.lazy(
  () => import("../../../views/ProviderCreateView")
);
const ProviderUpdate = React.lazy(
  () => import("../../../views/ProviderUpdateView")
);

const DashboardMarketView = React.lazy(
  () => import("../../../views/DashboardMarketView")
);
const DashboardNationalStatisticsView = React.lazy(
  () => import("../../../views/DashboardNationalStatistics")
);

export default function RouteMap() {
  const { isAuth } = useAuthentication() || {};
  const location = useLocation();

  const HomePage = isAuth ? (
    <PrivateRoute
      component={<Dashboard />}
      roles={[
        AuthorizationRoles.SYSTEM_ADMIN,
        AuthorizationRoles.SUPER_ADMIN,
        AuthorizationRoles.CLIENT_USER,
      ]}
    />
  ) : (
    <LandingPage />
  );

  return (
    <AnimatePresence>
      <Suspense fallback={<FallBack />}>
        <Routes location={location} key={location.pathname}>
          <Route
            path={ROUTES.ROOT}
            element={<NavigationLayout auth={!!isAuth} />}
          >
            <Route index element={HomePage} />
            <Route path="/loading-view" element={<FallBack />} />
            <Route path={ROUTES.ABOUT} element={<AboutUs />} />
            <Route path={ROUTES.REQUEST} element={<RequestDemo />} />
            <Route path={ROUTES.CONTACT} element={<ContactUs />} />
            <Route
              path={ROUTES.MARKETMAP}
              element={
                <PrivateRoute
                  component={<DashboardMarketView />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.NATIONALSTATISTICS}
              element={
                <PrivateRoute
                  component={
                    <Suspense fallback={<FallBack />}>
                      <DashboardNationalStatisticsView />
                    </Suspense>
                  }
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.ACCOUNTLIST}
              element={
                <PrivateRoute
                  component={<AccountList />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.ACCOUNTNEW}
              element={
                <PrivateRoute
                  component={<AccountCreate />}
                  roles={[AuthorizationRoles.SYSTEM_ADMIN]}
                />
              }
            />
            <Route
              path={ROUTES.ACCOUNT}
              element={
                <PrivateRoute
                  component={<AccountUpdate />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.ASSESSMENTINPROGRESS}
              element={
                <PrivateRoute
                  component={<AssessmentInProgress />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.ASSESSMENTINFO}
              element={
                <PrivateRoute
                  component={<AssessmentInfo />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.HOSPITALROSTER}
              element={
                <PrivateRoute
                  component={<HospitalRoster />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.HOSPITALROSTERINFO}
              element={
                <PrivateRoute
                  component={<HospitalRosterInfo />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.MASTERPROVIDERLIST}
              element={
                <PrivateRoute
                  component={<MasterProviderList />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.USERPROFILE}
              element={
                <PrivateRoute
                  component={<UserProfile />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.USERLIST}
              element={
                <PrivateRoute
                  component={<UserList />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.DATASOURCE}
              element={
                <PrivateRoute
                  component={<DataSourceList />}
                  roles={[AuthorizationRoles.SYSTEM_ADMIN]}
                />
              }
            />
            <Route
              path={ROUTES.USERNEW}
              element={
                <PrivateRoute
                  component={<UserCreate />}
                  roles={[AuthorizationRoles.SYSTEM_ADMIN]}
                />
              }
            />
            <Route
              path={ROUTES.USER}
              element={
                <PrivateRoute
                  component={<UserUpdate />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.PROVIDERLIST}
              element={
                <PrivateRoute
                  component={<ProviderList />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
            <Route
              path={ROUTES.PROVIDERNEW}
              element={
                <PrivateRoute
                  component={<ProviderCreate />}
                  roles={[AuthorizationRoles.SYSTEM_ADMIN]}
                />
              }
            />
            <Route
              path={ROUTES.PROVIDER}
              element={
                <PrivateRoute
                  component={<ProviderUpdate />}
                  roles={[
                    AuthorizationRoles.SYSTEM_ADMIN,
                    AuthorizationRoles.SUPER_ADMIN,
                    AuthorizationRoles.CLIENT_USER,
                  ]}
                />
              }
            />
          </Route>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.FORGOT} element={<ForgotPassword />} />
          <Route path={ROUTES.RESET} element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}
