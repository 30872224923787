import {
  readFromStorage,
  writeToStorage,
  deleteFromStorage,
} from "../localStorage";
import { StoredToken } from "./types";

export const TOKEN_SYMBOL = {
  ACCESS: "ACCESS",
  REFRESH: "REFRESH",
};

export const TOKEN_EXPIRES_AT = {
  ACCESS: 5, // 5 mins
  REFRESH: 24 * 60, // 24 hours
};

export const getTokenFromStorage = (token: string) => {
  const stringToken = readFromStorage(token);
  if (stringToken) {
    return JSON.parse(stringToken) as StoredToken;
  }
  return null;
};

export const saveTokenIntoStorage = (token: string, value: StoredToken) => {
  writeToStorage(token, JSON.stringify(value));
};

export const removeTokenFromStorage = (token: string) => {
  deleteFromStorage(token);
};

export const isTokenValid = (expireLimit: number) =>
  new Date().getTime() < expireLimit;

export const getExpireTime = (minutes: number) =>
  new Date().getTime() + minutes * 60000;

export const formatToken = (token: string, minutes: number) => ({
  token,
  expiresAt: getExpireTime(minutes),
});

export * from "./types";
