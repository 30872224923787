/**
 * A function to check and return an specific environment setting based
 * on a presented string token.
 * @param key A string token referring to an environment setting value.
 * @returns The actual value of the environment setting.
 */
export function getEnvironmentVariable(key: string) {
  const returnedValue = process.env[key];
  if (!returnedValue) {
    throw new Error(`[Application] Environment setting ${key} is not defined!`);
  }
  return returnedValue;
}

export default {
  // API_ROOT: "https://staging-backend.needsmd.com",
  API_ROOT: getEnvironmentVariable("REACT_APP_API_ROOT"),
  MUI_KEY: getEnvironmentVariable("REACT_APP_MUI_KEY"),
  MASTER_USER: getEnvironmentVariable("REACT_APP_MASTER_USER"),
};
