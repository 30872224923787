export const STORE_PREFIX = "@needsmd";

export const STORE_NAMES = {
  ACCOUNT: "account",
  ADDRESS: "address",
  ADDRESS_HISTORY: "addressHistory",
  ASSESSMENT: "assessment",
  COMMENT: "comment",
  PROJECT_COMMENT: "projectComment",
  COMMENT_HISTORY: "commentHistory",
  COUNTY: "county",
  DISCHARGE: "discharge",
  FACILITY: "facility",
  GROUP: "group",
  PROJECT_GROUP: "projectGroup",
  GROUP_HISTORY: "groupHistory",
  HISTORY: "history",
  HOSPITAL: "hospital",
  PROJECT_HOSPITAL: "projectHospital",
  HOSPITAL_HISTORY: "hospitalHistory",
  NPI: "npi",
  NPI_HISTORY: "npiHistory",
  PROVIDER: "provider",
  PROVIDER_HISTORY: "providerHistory",
  PROVIDER_EXPORT: "providerExport",
  PROVIDER_ROLE: "providerRole",
  PROVIDER_TAXONOMY: "providerTaxonomy",
  PROVIDER_TAXONOMY_HISTORY: "state",
  PROJECT_ADDRESS: "projectAddress",
  PROJECT_DOWNLOAD_REPORT: "projectDownloadReport",
  PROJECT_PROVIDER: "projectProvider",
  PROJECT_PROVIDER_HISTORY: "projectProviderHistory",
  PROJECT_PROVIDER_EXPORT: "projectProviderExport",
  PROJECT_PROVIDER_ROLE: "projectProviderRole",
  PROJECT_SUMMARY_CLASS: "projectSummaryClass",
  PROJECT_TAXONOMY: "projectTaxonomy",
  PROJECT_PROVIDER_TAXONOMY: "projectProviderTaxonomy",
  PROJECT_PROVIDER_TAXONOMY_HISTORY: "projectState",
  ROSTER: "roster",
  STATE: "providerTaxonomyHistory",
  TAXONOMY: "taxonomy",
  USER: "user",
  ZIP_CODE: "zipCode",
};
