import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchAddressesSagaFlow
 * A redux saga that fetch addresses from the NeedsMD Address Endpoint.
 */
// TODO: Complete revision of this saga code.
export const startFetchAddressesSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, params }) =>
    call<any>(API.Project.Address.npiAddressList, account, projectId, id, {
      params,
    }),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ADDRESS_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateAddressSagaFlow
 * A redux saga that create an address from the NeedsMD Address Endpoint.
 */
export const startCreateAddressSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, id, data, params }) =>
    call<any>(
      API.Project.Address.npiAddressCreate,
      account,
      projectId,
      id,
      data,
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_ADDRESS_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateAddressSagaFlow
 * A redux saga that update an address from the NeedsMD Address Endpoint.
 */
export const startUpdateAddressSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, data, params }) =>
    call<any>(
      API.Project.Address.npiAddressUpdate,
      account,
      projectId,
      npiId,
      id,
      data,
      {
        params,
      }
    ),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_ADDRESS_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateAddressSagaFlow
 * A redux saga that update an address from the NeedsMD Address Endpoint.
 */
export const startDeleteAddressSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ account, projectId, npiId, id, params }) =>
    call<any>(
      API.Project.Address.npiAddressDelete,
      account,
      projectId,
      npiId,
      id,
      "",
      {
        params,
      }
    ),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_ADDRESS_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/project-address",
  sagas: {
    fetchProjectAddresses: startFetchAddressesSagaFlow,
    createProjectAddress: startCreateAddressSagaFlow,
    updateProjectAddress: startUpdateAddressSagaFlow,
    deleteProjectAddress: startDeleteAddressSagaFlow,
  },
});
