// import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
// import Actions from "./actions";
// import { makeEntityActionSaga } from "../../utils/sagas";
// import API from "../../../api";

/**
 * @name startFetchProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
// TODO: Complete revision of this saga code.
// export const startFetchProvidersSagaFlow = makeEntityActionSaga({
//   onStart: () => put(Actions.setNetworkIdle()),
//   onCall: (params) => call<any>(API.Provider.providerList, "", { params }),
//   // TODO: Normalize these results before saving them into Redux.
//   onResponse: (response) => put(Actions.upsertMany(response.data.results)),
//   onFinish: () => put(Actions.setNetworkAvailable()),
//   onError: (err) =>
//     put(
//       Actions.setError({
//         type: "FETCH_PROVIDER_ERROR",
//         message: err.message,
//       })
//     ),
// });

export default createSagaModule({
  name: "@intechideas/needsmd/provider",
  sagas: {
    // fetchProviders: startFetchProvidersSagaFlow,
  },
});
