import { useContext } from "react";
import { AuthContext } from "./context";

export const useAuthentication = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      '"useAuthentication" should be called inside a "AuthProvider" component!'
    );
  }

  return context;
};
