import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleAccountSagaFlow
 * A redux saga that fetch a single Account from the NeedsMD Account Endpoint.
 */

export const startFetchAccountListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.Account.accountList, "", { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data.results)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ACCOUNT_LIST_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchSingleAccountSagaFlow
 * A redux saga that fetch a single Account from the NeedsMD Account Endpoint.
 */

export const startFetchSingleAccountSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, params }) => call<any>(API.Account.account, id, { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_SINGLE_ACCOUNT_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateAccountSagaFlow
 * A redux saga that update a single Account from the NeedsMD Account Endpoint.
 */

export const startUpdateAccountSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data, params }) =>
    call<any>(API.Account.accountUpdate, id, data, { params }),
  onResponse: (response) => put(Actions.upsertOne(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_ACCOUNT_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/account",
  sagas: {
    fetchAccountList: startFetchAccountListSagaFlow,
    fetchAccount: startFetchSingleAccountSagaFlow,
    updateAccount: startUpdateAccountSagaFlow,
  },
});
