import { all, call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import UserActions from "../user/actions";
import { normalizeCommentSet } from "./utils";

/**
 * @name startFetchCommentsSagaFlow
 * A redux saga that fetch comments from the NeedsMD Comment Endpoint.
 */
export const startFetchCommentsSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, params }) =>
    call<any>(API.Comment.npiCommentsList, id, { params }),
  onResponse: (response) => {
    const normalizedSet = normalizeCommentSet(response.data.results);

    const actions: any = [
      put(UserActions.upsertMany(normalizedSet.user)),
      put(Actions.upsertMany(normalizedSet.comment)),
    ];

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_COMMENTS_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateCommentSagaFlow
 * A redux saga that create a comment from the NeedsMD Comment Endpoint.
 */
export const startCreateCommentSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data, params }) =>
    all([
      call<any>(API.Comment.npiCommentCreate, id, data, { params }),
      call<any>(API.User.user, data.user, { params }),
    ]),
  onResponse: (response) =>
    all([
      put(
        Actions.upsertOne({
          ...response[0].data.data,
          user: response[0].data.data?.user?.id,
        })
      ),
      put(UserActions.upsertOne(response[1].data.data)),
    ]),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_COMMENT_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateCommentSagaFlow
 * A redux saga that update a Comment from the NeedsMD Comment Endpoint.
 */
export const startUpdateCommentSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, data, params }) =>
    call<any>(API.Comment.npiCommentsUpdate, id, data, { params }),
  onResponse: (response) => {
    const normalizedSet = normalizeCommentSet([response.data.data]);

    const actions: any = [
      put(UserActions.upsertMany(normalizedSet.user)),
      put(Actions.upsertMany(normalizedSet.comment)),
    ];

    return all(actions);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_COMMENT_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteCommentSagaFlow
 * A redux saga that deletes a comment from the NeedsMD Comment Endpoint.
 */
export const startDeleteCommentSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, params }) =>
    call<any>(API.Comment.npiCommentsDelete, id, { params }),
  onResponse: (response, data) => put(Actions.removeOne(data.id)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "REMOVE_COMMENT_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/comment",
  sagas: {
    fetchComments: startFetchCommentsSagaFlow,
    createComment: startCreateCommentSagaFlow,
    updateComment: startUpdateCommentSagaFlow,
    deleteComment: startDeleteCommentSagaFlow,
  },
});
