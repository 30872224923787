import React from "react";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { QueryClient, QueryClientProvider } from "react-query";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { getStylesTheme } from "../../styles";
import RouteMap from "../routes/fragments/RouteMap";
import { AuthProvider } from "../auth/providers/AuthProvider";
import ENVIRONMENT from "../../constants/environment";
import store from "../../redux/store";
import SagaManager from "../../services/reduxSaga";
import RootSaga from "../../services/reduxSaga/rootSaga";
import AuthorizationProvider from "../authorization/providers/AuthorizationProvider/provider";
import { AuthorizationComponents } from "../../constants/authorization/views";
import ScrollToTop from "../commons/fragments/ScrollToTop";
import MessageCenterProvider from "../messageCenter/providers/MessageCenterProvider";

// Query Client used by React Query provider.
const queryClient = new QueryClient();

// License validation for MUI Pro.
LicenseInfo.setLicenseKey(ENVIRONMENT.MUI_KEY);

// Saga Manager used by Redux Saga.
SagaManager.run(RootSaga);

function App() {
  const muTheme = responsiveFontSizes(getStylesTheme("light"));

  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AuthorizationProvider component={AuthorizationComponents.DEFAULT}>
              <MessageCenterProvider>
                <ThemeProvider theme={muTheme}>
                  <CssBaseline />
                  <ScrollToTop />
                  <RouteMap />
                </ThemeProvider>
              </MessageCenterProvider>
            </AuthorizationProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
