import { AxiosRequestConfig } from "axios";
import ApiClient from "../../services/apiClient";

export const getSpecialtyClassList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/specialtyclass`, opts);

export const getSpecialtyList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/specialty`, opts);

export const getTaxonomyList = (opts?: AxiosRequestConfig) =>
  ApiClient.get(`/taxonomy`, opts);

export const createSingleTaxonomy = (data: any, opts?: AxiosRequestConfig) =>
  ApiClient.post("/taxonomy", data, opts);

export const getSingleTaxonomy = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.get(`/taxonomy/${id}`, opts);

export const updateSingleTaxonomy = (
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) => ApiClient.put(`/taxonomy/${id}`, data, opts);

export const deleteSingleTaxonomy = (id: any, opts?: AxiosRequestConfig) =>
  ApiClient.delete(`/taxonomy/${id}`, opts);
