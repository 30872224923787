import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const npiGroupList = (
  account: string,
  projectId: string,
  id: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${id}/group`,
    opts
  );

export const npiGroupCreate = (
  account: string,
  projectId: string,
  npiId: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.post(
    `/account/${account}/project/${projectId}/provider/${npiId}/group`,
    data,
    opts
  );

export const npiGroup = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/provider/${npiId}/group/${id}`,
    opts
  );

export const npiGroupUpdate = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  data: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.put(
    `/account/${account}/project/${projectId}/provider/${npiId}/group/${id}`,
    data,
    opts
  );

export const npiGroupDelete = (
  account: string,
  projectId: string,
  npiId: any,
  id: any,
  opts?: AxiosRequestConfig
) =>
  ApiClient.delete(
    `/account/${account}/project/${projectId}/provider/${npiId}/group/${id}`,
    opts
  );

export * as History from "./history";
