import { EntitySelectors, createSelector } from "@reduxjs/toolkit";

import Factory from "./factory";
import { IGroup } from "./types";
import { RootState } from "../../store";

export const getSlice = (state: RootState) => state.groups;

// *********************
// Redux Core Selectors
// *********************
export const Entity: EntitySelectors<
  IGroup, // Entity Slice Type
  RootState // EQM state type
> = Factory.selectors.getEntitySelectors(getSlice);
export const Error = Factory.selectors.getErrorSelectors(getSlice);
export const Network = Factory.selectors.getNetworkSelectors(getSlice);

// *****************
// Custom Selectors
// *****************
// export const Custom = {};

export const getGroupsByNPI = createSelector(
  (state: RootState, props: { id: number }) => props,
  (state: RootState) => Entity.selectAll(state),
  (props, groups) => groups.filter((group) => group.provider === props.id)
);

export default {
  Entity,
  Error,
  Network,
  // Custom,
};
