import { createCoreEntityAdapter } from "../../../utils/core";
import { STORE_NAMES } from "../../../constants";
import { INormalizedNpiHistory } from "./types";

export default createCoreEntityAdapter<INormalizedNpiHistory>(
  STORE_NAMES.NPI_HISTORY,
  {
    // eslint-disable-next-line no-underscore-dangle
    selectId: (entity) => entity.__id__,
  }
);
