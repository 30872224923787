import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startFetchSingleAssessmentSagaFlow
 * A redux saga that fetch a single Assessment from the NeedsMD Assessment Endpoint.
 */

export const startFetchAssessmentListSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ params }) => call<any>(API.Assessment.assessmentList, { params }),
  onResponse: (response) => put(Actions.upsertMany(response.data.data)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_ASSESSMENT_LIST_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/assessment",
  sagas: {
    fetchAssessmentList: startFetchAssessmentListSagaFlow,
  },
});
