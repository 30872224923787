import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const specialtyRoster = (
  account: string,
  projectId: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/demographic/specialty/roster`,
    opts
  );

export const specialtyAssessment = (
  account: string,
  projectId: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/demographic/specialty/assessment`,
    opts
  );

export const privilegeRoster = (
  account: string,
  projectId: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/demographic/privilege/roster`,
    opts
  );

export const privilegeAssessment = (
  account: string,
  projectId: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `/account/${account}/project/${projectId}/demographic/privilege/assessment`,
    opts
  );
