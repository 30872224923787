import React from "react";
import { IMessage } from "./types";

export interface I {}

export interface IMessageCenterContext {
  messages: IMessage[];
  onAddMessage: (message: IMessage) => void;
  onRemoveMessage: (messageId: IMessage["id"]) => void;
}

export const MessageCenterContext = React.createContext<IMessageCenterContext>({
  messages: [],
  onAddMessage: () => {},
  onRemoveMessage: () => {},
});

export const { Provider } = MessageCenterContext;
