export enum AuthorizationRoles {
  SUPER_ADMIN = "super_admin",
  SYSTEM_ADMIN = "system_admin",
  CLIENT_USER = "client_user",
  DEFAULT = "default",
}

export type AuthorizationRoleList = AuthorizationRoles[];

export const ADMIN_ROLES = [
  AuthorizationRoles.SYSTEM_ADMIN,
  AuthorizationRoles.SUPER_ADMIN,
];
