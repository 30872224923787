import { call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import ProviderActions from "./actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";

/**
 * @name startExportProvidersSagaFlow
 * A redux saga that export the providers export from the NeedsMD Provider Endpoint.
 */
export const startExportProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: (params) => call<any>(API.Provider.providerList, "", { params }),
  onResponse: (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${new Date()
        .toLocaleString()
        .replace(/\/|:|,|\s+/g, "-")
        .toLowerCase()}-Providers-file.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();

    return null;
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "EXPORT_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/providerExport",
  sagas: {
    exportProviders: startExportProvidersSagaFlow,
  },
});
