import { AxiosRequestConfig } from "axios";
import ApiClient from "../../../services/apiClient";

export const fetchList = (
  projectId: string,
  groupId: string,
  opts?: AxiosRequestConfig
) =>
  ApiClient.get(
    `account/${groupId}/project/${projectId}/provider/specialty`,
    opts
  );
