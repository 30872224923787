import { createSelector } from "@reduxjs/toolkit";

import { NetworkState, NetworkStateSlice } from "./types";

export interface NetworkSelectors<V> {
  getNetworkState: (state: V) => NetworkState;
  getNetworkStatus: (state: V) => boolean;
}

export function getSelectors(): NetworkSelectors<NetworkStateSlice>;
export function getSelectors<V>(
  selectState: (state: V) => NetworkStateSlice
): NetworkSelectors<V>;

export function getSelectors<V>(
  selectState?: (state: V) => NetworkStateSlice
): NetworkSelectors<NetworkStateSlice> {
  const getNetworkState = createSelector(
    (state: NetworkStateSlice) => state.network,
    (state) => state
  );

  const getNetworkStatus = createSelector(
    [getNetworkState],
    (state: NetworkState) => state.isFetching
  );

  if (!selectState) {
    return {
      getNetworkState,
      getNetworkStatus,
    };
  }

  return {
    getNetworkState: createSelector(selectState, getNetworkState),
    getNetworkStatus: createSelector(selectState, getNetworkStatus),
  } as never;
}
