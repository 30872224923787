export const entityToMap = (entity: any, map: Map<string, any>) => {
  map.set(entity.id, entity);
  return entity.id;
};

export const getArrayFromMap = (map: Map<string, any>) =>
  Array.from(map.values());

export const normalizeCommentSet = (comments: any[]) => {
  const commentMap = new Map();
  const userMap = new Map();
  const npiMap = new Map();

  comments.forEach((comment) => {
    const newComment = { ...comment };

    // Add users to UserMap
    newComment.user = entityToMap(comment.user, userMap);
    newComment.npi = entityToMap(comment.npi, npiMap);

    entityToMap({ ...newComment, npi: comment.npi }, commentMap);
  });

  return {
    comment: getArrayFromMap(commentMap),
    user: getArrayFromMap(userMap),
    npi: getArrayFromMap(npiMap),
  };
};
