import React from "react";
import { AuthorizationRoles } from "../../../../constants/authorization/roles";
import { IAccountData } from "../../../account/forms/CreateAccountForm/types";
import { Session } from "./types";

export interface IUser {
  role: AuthorizationRoles;
  first_name: string;
  last_name: string;
  email: string;
  id: number;
  account: number[];
}

export interface IAuthContext {
  isAuth: boolean;
  isLoading: boolean;
  onLoginSuccess: (session: Session) => void;
  onLogoutSuccess: () => void;
  setSimulateRole: (role: string) => void;
  user: IUser;
  account: IAccountData;
  isAdmin: boolean;
  isSystemAdmin: boolean;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined
);

export const { Provider } = AuthContext;
