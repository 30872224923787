/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Provider } from "./context";
import {
  saveTokenIntoStorage,
  getTokenFromStorage,
  removeTokenFromStorage,
  isTokenValid,
  formatToken,
  TOKEN_SYMBOL,
  TOKEN_EXPIRES_AT,
} from "../../../../services/authentication";
import {
  createStartAuthInterceptor,
  stopAuthInterceptor,
} from "../../../../services/apiClient";
import API from "../../../../api";
import { Session } from "./types";
import ROUTES from "../../../../constants/routes";
import { parseJwt } from "./utils";
import { ADMIN_ROLES } from "./constants";

export interface IAuthProviderProps {}

export function AuthProvider(
  props: React.PropsWithChildren<IAuthProviderProps>
) {
  const [token, setToken] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [simulateRole, setSimulateRole] = React.useState("");
  const navigate = useNavigate();
  const { children } = props;

  /**
   * Function that deletes tokens from LocalStorage and
   * sets the application in an unauthorized state.
   */
  const unsetTokenSession = () => {
    removeTokenFromStorage(TOKEN_SYMBOL.ACCESS);
    removeTokenFromStorage(TOKEN_SYMBOL.REFRESH);

    setToken(null);

    stopAuthInterceptor();
  };

  /**
   * Function fired if the request's interceptor falls into
   * an unauthorized result.
   */
  const onInterceptorLogout = () => {
    unsetTokenSession();
    navigate(ROUTES.LOGIN);
  };

  /**
   * Function fired if an authorized app state is validated.
   * This will set logged in state and initialize the request
   * interceptor.
   */
  const setTokenSession = (sessionToken: string) => {
    setToken(sessionToken);
    createStartAuthInterceptor(API, onInterceptorLogout)();
  };

  React.useEffect(() => {
    /**
     * Function that checks the existance of tokens in the localStorage
     * and sets the appropriate application state based on their status.
     */
    async function validateSession() {
      const accessSession = getTokenFromStorage(TOKEN_SYMBOL.ACCESS);
      const refreshSession = getTokenFromStorage(TOKEN_SYMBOL.REFRESH);

      // If Access Token exists and is valid...
      if (accessSession && isTokenValid(accessSession.expiresAt)) {
        setTokenSession(accessSession.token);
      } else if (
        // If not, check if Refresh Token exists...
        refreshSession?.token &&
        isTokenValid(refreshSession.expiresAt)
      ) {
        const response = await API.Auth.refresh({
          refresh: refreshSession.token,
        });

        if (response) {
          const newAccessSession = formatToken(
            response.data.access,
            TOKEN_EXPIRES_AT.ACCESS
          );
          saveTokenIntoStorage(TOKEN_SYMBOL.ACCESS, newAccessSession);
          setTokenSession(response.data.access);
        }
      } else {
        // If not successful, set as "Logged Out".
        unsetTokenSession();
      }

      setIsLoading(false);
    }

    validateSession();
  }, []);

  /**
   * A function handler to be called once a Login operation is
   * deemed successful.
   * @param loginResponse Response data from the "/auth/login" operation.
   */
  const onLoginSuccess = (loginResponse: Session) => {
    // Get tokens to match session format and give them their expiry date.
    const newAccessSession = formatToken(
      loginResponse.access,
      TOKEN_EXPIRES_AT.ACCESS
    );
    const newRefreshSession = formatToken(
      loginResponse.refresh,
      TOKEN_EXPIRES_AT.REFRESH
    );

    // Save and set tokens.
    saveTokenIntoStorage(TOKEN_SYMBOL.ACCESS, newAccessSession);
    saveTokenIntoStorage(TOKEN_SYMBOL.REFRESH, newRefreshSession);
    setTokenSession(loginResponse.access);
  };

  /**
   * A function handler to be called once a Logout operation is
   * deemed successful.
   */
  const onLogoutSuccess = () => {
    navigate(ROUTES.ROOT);
    unsetTokenSession();
  };

  const user = token ? parseJwt(token) : null;
  const userWithRole = user
    ? { ...user, role: simulateRole !== "" ? simulateRole : user.role }
    : user;

  return (
    <Provider
      value={{
        isAuth: !!token,
        isLoading,
        onLoginSuccess,
        onLogoutSuccess,
        user: userWithRole,
        isAdmin: ADMIN_ROLES.includes(userWithRole?.role),
        isSystemAdmin: userWithRole?.role === "system_admin",
        setSimulateRole,
        account: {
          id: user ? user.account[0] : 0,
        },
      }}
    >
      {/* TODO: Add loading screen to the app */}
      {isLoading ? null : children}
    </Provider>
  );
}

export default AuthProvider;
