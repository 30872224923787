export enum AuthorizationComponents {
  DEFAULT = "default",
  PROVIDER = "provider",
  MASTER_PROVIDER = "master_provider",
  DASHBOARD = "dashboard",
  MANAGE_USER = "manage_user",
  MANAGE_ACCOUNT = "manage_account",
  USER_PROFILE = "user_profile",
  ROSTER_HOSPITAL = "roster_hospital",
  ASSESSMENT_IN_PROGRESS = "assessment_in_progress",
}

export type AuthorizationComponentList = AuthorizationComponents[];
