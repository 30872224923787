import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import ProviderActions from "./actions";
import AddressActions from "../address/actions";
import TaxonomyActions from "../taxonomy/actions";
import ProviderTaxonomyActions from "../providerTaxonomy/actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import { normalizeProviderSet } from "./utils";

/**
 * @name startFetchSimpleProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchSimpleProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: (params) => call<any>(API.Provider.providerList, "", { params }),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet(response.data.results);
    const actions: any = [];

    if (response.data.message) {
      actions.push(
        put(
          ProviderActions.setError({
            type: "FETCH_PROVIDER_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    actions.push(put(ProviderActions.setPagination(response.data)));
    actions.push(put(ProviderActions.setAll(normalizedSet.providers)));

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: (params) => call<any>(API.Provider.providerList, "", { params }),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet(response.data.results);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    // if the server returns a message inside the response, we proceed to show it in the frontend
    if (response.data.message) {
      actions.push(
        put(
          ProviderActions.setError({
            type: "FETCH_PROVIDER_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProvidersSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ id, data, config }) =>
    call<any>(API.Provider.providerUpdate, id, data, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet([response.data.data]);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
      put(ProviderActions.upsertMany(normalizedSet.providers)),
    ];

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProvidersSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProviderListSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ config }) => call<any>(API.Provider.providersUpdate, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet(response.data.results);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchSingleProviderSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startFetchSingleProviderSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ id, config }) => call<any>(API.Provider.provider, id, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet([response.data.data]);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_SINGLE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateUnFlagProviderListSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateUnFlagProviderListSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ config }) => call<any>(API.Provider.providersUpdate, config),
  onAfter: (response, params) =>
    call<any>(startFetchProvidersSagaFlow, {
      action: "",
      payload: params.config2.params,
    }),
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteProvidersSagaFlow
 * A redux saga that deletes a provider from the NeedsMD Provider Endpoint.
 */
export const startDeleteProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ id, config }) =>
    call<any>(API.Provider.providerDelete, id, config),
  onAfter: ({ id }) => put(ProviderActions.removeOne(id)),
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "DELETE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/provider",
  sagas: {
    fetchProviders: startFetchProvidersSagaFlow,
    fetchSimpleProviders: startFetchSimpleProvidersSagaFlow,
    fetchSingleProvider: startFetchSingleProviderSagaFlow,
    updateProviderList: startUpdateProviderListSagaFlow,
    updateUnFlagProviderList: startUpdateUnFlagProviderListSagaFlow,
    updateProvider: startUpdateProvidersSagaFlow,
    deleteProvider: startDeleteProvidersSagaFlow,
  },
});
