import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  // Badge,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  useMediaQuery,
  ListItemIcon,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";
import Logo from "../../../commons/fragments/LogoComponent";
import { IBarComponentProps } from "./types";
import { ITheme } from "../../../../styles/types";
import { useAuthentication } from "../../../auth/providers/AuthProvider/hooks";
import ROUTES from "../../../../constants/routes";
import API from "../../../../api";

/**
 * Styles for BarComponent
 */
const useStyles = makeStyles<ITheme>((theme) =>
  createStyles({
    appBar: {
      justifyContent: "center",
      backgroundColor: `${theme.palette.appBar.background} !important`,
      color: `${theme.palette.appBar.color} !important`,
    },
    logoLink: {
      height: "100%",
    },
    logo: {
      height: "100%",
      width: "auto",
      padding: "0.6em",
    },
  })
);

/**
 * Renders the BarComponent
 */
const BarComponent = ({
  auth,
  mailTotal,
  notificationTotal,
  ...rest
}: IBarComponentProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { onLogoutSuccess } = useAuthentication() || {};
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Boolean to check if the screen is small
  const isMediumView = useMediaQuery((theme: ITheme) =>
    theme.breakpoints.down("sm")
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate(ROUTES.USERPROFILE);
    handleClose();
  };

  const onLogout = async () => {
    // try {
    await API.Auth.logout();
    if (onLogoutSuccess) {
      onLogoutSuccess();
      navigate(ROUTES.ROOT);
    }
    // } catch (err) {
    //   console.error(err);
    // }
  };

  const navigator = (url: string) => () => {
    navigate(url);
    handleClose();
  };

  const renderNoAuthMenu = () => {
    // Validates if the Screen is small, then renders the pop menu
    if (isMediumView) {
      return (
        <>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenu}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={navigator(ROUTES.ABOUT)}>About Us</MenuItem>
            <MenuItem onClick={navigator(ROUTES.CONTACT)}>Contact Us</MenuItem>
            <MenuItem onClick={navigator(ROUTES.LOGIN)}>
              Login to NeedsMD
            </MenuItem>
          </Menu>
        </>
      );
    }

    // otherwise renders the buttons menu
    return (
      <>
        <Button href={ROUTES.ABOUT} color="inherit" sx={{ m: 1 }}>
          About Us
        </Button>
        <Button href={ROUTES.CONTACT} color="inherit" sx={{ m: 1 }}>
          Contact Us
        </Button>
        <Button
          href={ROUTES.LOGIN}
          variant="outlined"
          color="inherit"
          startIcon={<PersonIcon />}
          sx={{ m: 1 }}
        >
          Login to NeedsMD
        </Button>
      </>
    );
  };

  // TODO: Uncomment utility icons once their features are defined.
  const renderAuthMenu = () => {
    return (
      <div>
        {/* <IconButton size="large" color="inherit">
          <Badge badgeContent={notificationTotal} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton size="large" color="inherit">
          <Badge badgeContent={mailTotal} color="error">
            <MailIcon />
          </Badge>
        </IconButton> */}
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 18,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleProfile}>
            <ListItemIcon>
              <PermIdentityIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <AppBar elevation={1} className={classes.appBar} {...rest}>
      <Toolbar>
        <Link className={classes.logoLink} to={ROUTES.ROOT}>
          <Logo className={classes.logo} />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {auth ? renderAuthMenu() : renderNoAuthMenu()}
      </Toolbar>
    </AppBar>
  );
};

BarComponent.defaultProps = {
  mailTotal: 0,
  notificationTotal: 0,
};

export * from "./types";
export default BarComponent;
