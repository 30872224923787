import * as Account from "./account";
import * as Address from "./address";
import * as Assessment from "./assessment";
import * as Auth from "./auth";
import * as Client from "./client";
import * as Comment from "./comment";
import * as County from "./county";
import * as DataSource from "./dataSource";
import * as Discharges from "./discharges";
import * as Facility from "./facility";
import * as Group from "./group";
import * as Hospital from "./hospital";
import * as NPI from "./npi";
import * as Project from "./project";
import * as Provider from "./provider";
import * as ProviderRole from "./providerRole";
import * as Roster from "./roster";
import * as State from "./state";
import * as Taxonomy from "./taxonomy";
import * as User from "./user";
import * as ZipCode from "./zipCode";

export default {
  Account,
  Address,
  Assessment,
  Auth,
  Client,
  County,
  Comment,
  DataSource,
  Discharges,
  Facility,
  Group,
  Hospital,
  NPI,
  Project,
  Provider,
  ProviderRole,
  Roster,
  State,
  Taxonomy,
  User,
  ZipCode,
};
