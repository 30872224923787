import { all, call, put } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import Actions from "./actions";
import TaxonomyAction from "../taxonomy/actions";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import { normalizeProviderTaxonomySet } from "./utils";

/**
 * @name startFetchProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchProviderTaxonomiesSagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ id, config }) =>
    call<any>(API.Provider.Taxonomy.getProviderTaxonomyList, id, config),
  onResponse: (response) => {
    const { providerTaxonomies, taxonomies } = normalizeProviderTaxonomySet(
      response.data.results
    );
    return all([
      put(Actions.upsertMany(providerTaxonomies)),
      put(TaxonomyAction.upsertMany(taxonomies)),
    ]);
  },
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "FETCH_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateProviderTaxonomySagaFlow
 * A redux saga that creates a provider taxonomy for a provider from the NeedsMD Provider Endpoint.
 */
export const startCreateProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ providerId, data, config }) =>
    all([
      call<any>(
        API.Provider.Taxonomy.createProviderTaxonomy,
        providerId,
        data,
        config
      ),
    ]),
  onResponse: (response, { providerId }) =>
    all([
      put(Actions.upsertOne(response[0].data.data)),
      call(startFetchProviderTaxonomiesSagaFlow, {
        payload: { id: providerId },
      }),
    ]),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "CREATE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteProviderTaxonomySagaFlow
 * A redux saga that deletes a taxonomy from a provider from the NeedsMD Provider Endpoint.
 */
export const startDeleteProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ providerId, taxonomyId, config }) =>
    call<any>(
      API.Provider.Taxonomy.deleteSingleProviderTaxonomy,
      { providerId, taxonomyId },
      config
    ),
  onResponse: (response, context) => put(Actions.removeOne(context.taxonomyId)),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "DELETE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProviderTaxonomySagaFlow
 * A redux saga that updates a provider taxonomy for a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProviderTaxonomySagaFlow = makeEntityActionSaga({
  onStart: () => put(Actions.setNetworkIdle()),
  onCall: ({ providerId, providerTaxonomyId, data, config }) =>
    all([
      call<any>(
        API.Provider.Taxonomy.updateSingleProviderTaxonomy,
        { providerId, taxonomyId: providerTaxonomyId },
        data,
        config
      ),
      call<any>(API.Taxonomy.getSingleTaxonomy, data.taxonomy),
    ]),
  onResponse: (response, { providerId }) =>
    all([
      put(Actions.upsertOne(response[0].data.data)),
      call(startFetchProviderTaxonomiesSagaFlow, {
        payload: { id: providerId },
      }),
    ]),
  onFinish: () => put(Actions.setNetworkAvailable()),
  onError: (err) =>
    put(
      Actions.setError({
        type: "UPDATE_PROVIDER_TAXONOMY_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@needsmd/provider",
  sagas: {
    fetchProviderTaxonomies: startFetchProviderTaxonomiesSagaFlow,
    createProviderTaxonomy: startCreateProviderTaxonomySagaFlow,
    updateProviderTaxonomy: startUpdateProviderTaxonomySagaFlow,
    deleteProviderTaxonomy: startDeleteProviderTaxonomySagaFlow,
  },
});
