import { call, put, all } from "redux-saga/effects";
import createSagaModule from "../../utils/sagas/toolkit";
import ProviderActions from "./actions";
import AddressActions from "../projectAddress/actions";
import TaxonomyActions from "../projectTaxonomy/actions";
import ProviderTaxonomyActions from "../projectProviderTaxonomy/actions";
import { startFetchProjectSummaryClass } from "../projectSumaryClass/sagas";
import { makeEntityActionSaga } from "../../utils/sagas";
import API from "../../../api";
import { normalizeProviderSet } from "./utils";

/**
 * @name startFetchSimpleProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchSimpleProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: (params) => call<any>(API.Project.providerList, { params }),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet(response.data.results);
    const actions: any = [];

    if (response.data.message) {
      actions.push(
        put(
          ProviderActions.setError({
            type: "FETCH_PROVIDER_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    actions.push(put(ProviderActions.setPagination(response.data)));
    actions.push(put(ProviderActions.setAll(normalizedSet.providers)));

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, id, params }) =>
    call<any>(API.Project.providerList, account, id, { params }),
  onResponse: (response, { includeClean = true, ignorePagination }) => {
    const normalizedSet = normalizeProviderSet(response.data.results);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    // if the server returns a message inside the response, we proceed to show it in the frontend
    if (response.data.message) {
      actions.push(
        put(
          ProviderActions.setError({
            type: "FETCH_PROVIDER_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    if (ProviderActions.setPagination) {
      if (!ignorePagination) {
        actions.push(put(ProviderActions.setPagination(response.data)));
      }

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (includeClean && response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchRosterProvidersSagaFlow
 * A redux saga that fetch providers from the NeedsMD Provider Endpoint.
 */
export const startFetchRosterProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, id, params }) =>
    call<any>(API.Project.rosterProviderList, account, id, { params }),
  onResponse: (response, { includeClean = true }) => {
    const normalizedSet = normalizeProviderSet(response.data.results);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    // if the server returns a message inside the response, we proceed to show it in the frontend
    if (response.data.message) {
      actions.push(
        put(
          ProviderActions.setError({
            type: "FETCH_PROVIDER_MESSAGE",
            message: response.data.message,
          })
        )
      );
    }

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (includeClean && response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchFTESagaFlow
 * A redux saga that create a FTE Adjusted from the NeedsMD FTE Endpoint.
 */
export const startFetchFTESagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ groupId, selectedId, providerId }) =>
    call<any>(
      API.Project.FTEAdjusted.npiFTEAdjustedList,
      groupId,
      selectedId,
      providerId
    ),
  onResponse: (response, { provider }) => {
    const newData = {
      ...provider,
      fte_adjusted: {
        id: response.data[0].id,
        value: response.data[0].value,
        reason: response.data[0].reason,
      },
    };

    const normalizedSet = normalizeProviderSet([newData]);

    return put(ProviderActions.upsertMany(normalizedSet.providers));
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_FTE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startCreateFTESagaFlow
 * A redux saga that create a FTE Adjusted from the NeedsMD FTE Endpoint.
 */
export const startCreateFTESagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ groupId, selectedId, providerId, data }) =>
    call<any>(
      API.Project.FTEAdjusted.npiFTEAdjustedCreate,
      groupId,
      selectedId,
      providerId,
      data
    ),
  onResponse: (response, { provider }) => {
    const newData = {
      ...provider,
      fte_adjusted: {
        id: response.data.data.id,
        value: response.data.data.value,
        reason: response.data.data.reason,
      },
    };

    const normalizedSet = normalizeProviderSet([newData]);

    return put(ProviderActions.upsertMany(normalizedSet.providers));
  },
  onAfter: (response, { groupId, selectedId, summaryPage }) => {
    const actions: any = [];

    if (summaryPage) {
      actions.push(
        call(startFetchProjectSummaryClass, {
          payload: {
            projectId: selectedId,
            groupId,
            includePagination: false,
            params: { page: summaryPage },
          },
        })
      );
    }
    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "CREATE_FTE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateFTESagaFlow
 * A redux saga that update a FTE Adjusted from the NeedsMD FTE Endpoint.
 */
export const startUpdateFTESagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ groupId, selectedId, providerId, id, data }) =>
    call<any>(
      API.Project.FTEAdjusted.npiFTEAdjustedUpdate,
      groupId,
      selectedId,
      providerId,
      id,
      data
    ),
  onResponse: (response, { id, provider }) => {
    const newData = {
      ...provider,
      fte_adjusted: {
        id,
        value: response.data.data.value,
        reason: response.data.data.reason,
      },
    };

    const normalizedSet = normalizeProviderSet([newData]);

    return put(ProviderActions.upsertMany(normalizedSet.providers));
  },
  onAfter: (response, { groupId, selectedId, summaryPage }) => {
    const actions: any = [];

    if (summaryPage) {
      actions.push(
        call(startFetchProjectSummaryClass, {
          payload: {
            projectId: selectedId,
            groupId,
            includePagination: false,
            params: { page: summaryPage },
          },
        })
      );
    }
    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_FTE_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProvidersSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, projectId, id, data, config }) =>
    call<any>(API.Project.providerUpdate, account, projectId, id, data, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet([response.data.data]);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
      put(ProviderActions.upsertMany(normalizedSet.providers)),
    ];

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateProvidersSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateProviderListSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, config }) =>
    call<any>(API.Provider.providersUpdate, account, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet(response.data.results);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startFetchSingleProviderSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startFetchSingleProviderSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, id, config }) =>
    call<any>(API.Provider.provider, account, id, config),
  onResponse: (response) => {
    const normalizedSet = normalizeProviderSet([response.data.data]);

    const actions: any = [
      put(AddressActions.upsertMany(normalizedSet.addresses)),
      put(TaxonomyActions.upsertMany(normalizedSet.taxonomies)),
      put(ProviderTaxonomyActions.upsertMany(normalizedSet.providerTaxonomy)),
    ];

    if (ProviderActions.setPagination) {
      actions.push(put(ProviderActions.setPagination(response.data)));

      // if the page is the first page, then we need to reset the data
      // overriding the old data, without jumping to blank screen.
      if (response.data.page && response.data.page === 1) {
        actions.push(put(ProviderActions.setAll(normalizedSet.providers)));
      } else {
        actions.push(put(ProviderActions.upsertMany(normalizedSet.providers)));
      }
    }

    return all(actions);
  },
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "FETCH_SINGLE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startUpdateUnFlagProviderListSagaFlow
 * A redux saga that update a provider from the NeedsMD Provider Endpoint.
 */
export const startUpdateUnFlagProviderListSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, config }) =>
    call<any>(API.Provider.providersUpdate, account, config),
  onAfter: (response, params) =>
    call<any>(startFetchProvidersSagaFlow, {
      action: "",
      payload: params.config2.params,
    }),
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "UPDATE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteProvidersSagaFlow
 * A redux saga that deletes a provider from the NeedsMD Provider Endpoint.
 */
export const startDeleteProvidersSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, id, config }) =>
    call<any>(API.Provider.providerDelete, account, id, config),
  onAfter: ({ id }) => put(ProviderActions.removeOne(id)),
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "DELETE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

/**
 * @name startDeleteProviderFlagSagaFlow
 * A redux saga that deletes a provider flag from the NeedsMD Provider Endpoint.
 */
export const startDeleteProviderFlagSagaFlow = makeEntityActionSaga({
  onStart: () => put(ProviderActions.setNetworkIdle()),
  onCall: ({ account, projectId, id, config }) =>
    call<any>(API.Project.providerDeleteFlag, account, projectId, id, config),
  onFinish: () => put(ProviderActions.setNetworkAvailable()),
  onError: (err) =>
    put(
      ProviderActions.setError({
        type: "DELETE_PROVIDER_ERROR",
        message: err.message,
      })
    ),
});

export default createSagaModule({
  name: "@intechideas/needsmd/project-provider",
  sagas: {
    fetchProjectProviders: startFetchProvidersSagaFlow,
    fetchRosterProviders: startFetchRosterProvidersSagaFlow,
    // fetchSimpleProviders: startFetchSimpleProvidersSagaFlow,
    // fetchSingleProvider: startFetchSingleProviderSagaFlow,
    updateProjectProviderList: startUpdateProviderListSagaFlow,
    updateUnFlagProjectProviderList: startUpdateUnFlagProviderListSagaFlow,
    updateProjectProvider: startUpdateProvidersSagaFlow,
    deleteProjectProvider: startDeleteProvidersSagaFlow,
    deleteProjectProviderFlag: startDeleteProviderFlagSagaFlow,
    createFTE: startCreateFTESagaFlow,
    updateFTE: startUpdateFTESagaFlow,
  },
});
