import React from "react";
import { Box } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MedicalServicesTwoToneIcon from "@mui/icons-material/MedicalServicesTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MapIcon from "@mui/icons-material/Map";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { PencilBoxOutline, TextAccount } from "mdi-material-ui";

import ROUTES from "../../../../constants/routes";

const getIcon = (pathname: string) => {
  switch (pathname) {
    case ROUTES.ROOT:
      return <AdjustIcon sx={{ fontSize: "3rem" }} />;
    case ROUTES.NATIONALSTATISTICS:
      return <AssignmentIcon sx={{ fontSize: "3rem" }} />;
    case ROUTES.MARKETMAP:
      return <MapIcon sx={{ fontSize: "3rem" }} />;
    case ROUTES.HOSPITALROSTER:
      return <TextAccount sx={{ fontSize: "3rem" }} />;
    case ROUTES.ASSESSMENTINPROGRESS:
      return <PencilBoxOutline sx={{ fontSize: "3rem" }} />;
    case ROUTES.USERLIST:
      return <AccountCircleIcon sx={{ fontSize: "3rem" }} />;
    case ROUTES.ACCOUNTLIST:
      return <CorporateFareIcon sx={{ fontSize: "3rem" }} />;
    case ROUTES.MASTERPROVIDERLIST:
      return <MedicalServicesTwoToneIcon sx={{ fontSize: "3rem" }} />;
    default:
      return <FindInPageIcon sx={{ fontSize: "3rem" }} />;
  }
};

const FallBack = ({ pathname }: { pathname: string }) => {
  return (
    <Box sx={{ position: "absolute", marginTop: "10px" }}>
      {getIcon(pathname)}
    </Box>
  );
};

export default FallBack;
