import { useContext } from "react";
import { AuthorizationProviderContext } from "./context";

export const useAuthorization = () => {
  const context = useContext(AuthorizationProviderContext);
  if (!context) {
    throw new Error(
      '[React] "useAuthorization" hook should be called inside a "AuthorizationProvider" component!.'
    );
  }
  return context;
};
