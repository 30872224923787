import * as React from "react";
import { AuthorizationActions } from "../../../../constants/authorization/actions";
import { AuthorizationMap } from "../../../../constants/authorization/authorization";
import { AuthorizationRoles } from "../../../../constants/authorization/roles";
import { AuthorizationComponents } from "../../../../constants/authorization/views";

export interface IAuthorizationProviderContextProps {
  component: AuthorizationComponents;
  canDoAction: (action: AuthorizationActions) => boolean;
  canRedirectTo: (component: AuthorizationComponents) => boolean;
  actions: AuthorizationActions[];
  role: AuthorizationRoles;
  authorizationMap: typeof AuthorizationMap;
  isAdminRole: boolean;
  isSystemAdmin: boolean;
}

export const AuthorizationProviderContext =
  React.createContext<IAuthorizationProviderContextProps>({
    component: AuthorizationComponents.DEFAULT,
    canDoAction: () => false,
    canRedirectTo: () => false,
    actions: [],
    role: AuthorizationRoles.DEFAULT,
    authorizationMap: AuthorizationMap,
    isAdminRole: false,
    isSystemAdmin: false,
  });
